import React, { Fragment } from "react";
import FinalResult from "../assets/BBHGame/SuccessResult.jpg";
import FailResult from "../assets/BBHGame/FailResult.jpg";
import ImageViewer from "../components/game/imagepanel/imagepanel";
import { GameLanguages } from "../container/Enums/Enums";
import { IResult } from "./TTResult";
import { StringToNumber } from "../utilities/util";
import { isBBHAllStepCompleted } from "../container/GameMenu/Menu";
import { IGameState } from "../context/GameState/GameStateInterface";

export const getGameResultByLanguage = (language: string): IResult[] => {
  switch (language) {
    case GameLanguages.ENUK:
      return Result;
    case GameLanguages.DUTCH:
      return Result;
    default:
      return Result;
  }
};
export const getResultByStep = (
  gameStepNo: string,
  gamestate: IGameState
): number => {
  const stepNo = StringToNumber(gameStepNo);
  if (isBBHAllStepCompleted(gamestate)) {
    return 0;
  }
  if (stepNo === 13 || gamestate.gameContinued) {
    return 2;
  }
  return 1;
};

const Result: IResult[] = [
  {
    Body: [
      <Fragment key={1}>
        <span style={{ color: "#7ED321", fontSize: "1.2em" }}>SUCCESS</span>
        <span key={2} className={"mt-2"}>
          You've successfully escaped the bank.
        </span>
        <ImageViewer
          linkImage={FinalResult}
          viewerImage={FinalResult}
          footerText={""}
          style={{ maxWidth: "45%", minWidth: "40%" }}
        ></ImageViewer>
      </Fragment>,
    ],
    TeamInfo: [
      <span style={{ color: "#7ED321", fontSize: "1.2em" }}>YOUR RESULTS</span>,
      <span className="mt-2" key={9}>
        Thank you for playing our online escape room.
        <br />
        We hope you had fun! Here are your results.
      </span>,
    ],
    Content: FinalResult,
    Footer: [
      <Fragment>
        {/* <span
          className={"mt-3"}
          key={111}
          style={{ color: "#7ED321", fontSize: "0.8em" }}
        >
          YOUR RESULTS
        </span>
        <span className={"mt-2 mb-3"} key={112}>
          Thanks for playing our online escape room.
          <br />
          We hope you had fun.
        </span> */}
      </Fragment>,
      <span key={2}></span>,
      <span key={3} className={"mt-2"}>
        Visit our website to discover more fun experiences.
      </span>,
    ],
  },
  {
    Body: [
      <Fragment key={2}>
        <span style={{ color: "#7ED321", fontSize: "1.2em" }}>
          FAILED TO ESCAPE
        </span>
        <span className={"mt-2"}>
          Mission aborted! The police is right behind me.<br></br>I'll get rid
          of any loot in case I get caught.
        </span>
        <ImageViewer
          linkImage={FailResult}
          viewerImage={FailResult}
          footerText={""}
          defaultScale={1}
          style={{ maxWidth: "65%", minWidth: "40%" }}
        ></ImageViewer>

        {/* <span className={"mt-3"}>
          If you want to continue solving challenges you haven't solved yet,
          press the Continue Playing button.<br></br>
          This won't change your result.
        </span>
        <span className={"mt-2"}>
          Visit our website to discover more fun experiences.
          <br />
          If you enjoyed it, share your experience on LinkedIn.
        </span> */}
      </Fragment>,
    ],
    TeamInfo: [
      <span style={{ color: "#7ED321", fontSize: "1.2em" }}>YOUR RESULTS</span>,
      <span className="mt-2" key={9}>
        Thank you for playing our online escape room.
        <br />
        We hope you had fun! Here are your results.
      </span>,
    ],
    Content: FailResult,
    Footer: [
      <Fragment>
        {/* <span
          className={"mt-3"}
          key={111}
          style={{ color: "#7ED321", fontSize: "0.8em" }}
        >
          YOUR RESULTS
        </span>
        <span className={"mt-2 mb-3"} key={112}>
          Thanks for playing our online escape room.
          <br />
          We hope you had fun.
        </span> */}
      </Fragment>,
      <span key={2}></span>,
      <span key={4} className={"mt-3"}>
        If you want to continue solving the challenges you haven't solved yet,
        press the Continue Playing button.
        <br />
        This won't change your results.
      </span>,
      <span key={3} className={"mt-2"}>
        Visit our website to discover more fun experiences.
      </span>,
    ],
  },
  {
    Body: [
      <Fragment key={1}>
        <span style={{ color: "#7ED321", fontSize: "1.2em" }}>SUCCESS</span>
        <span key={2} className={"mt-2"}>
          You've successfully escaped the bank.
        </span>
        <ImageViewer
          linkImage={FinalResult}
          viewerImage={FinalResult}
          footerText={""}
          style={{ maxWidth: "45%", minWidth: "40%" }}
        ></ImageViewer>
      </Fragment>,
    ],
    TeamInfo: [
      <span style={{ color: "#7ED321", fontSize: "1.2em" }}>YOUR RESULTS</span>,
      <span className="mt-2" key={9}>
        Thank you for playing our online escape room.
        <br />
        We hope you had fun! Here are your results.
      </span>,
    ],
    Content: FinalResult,
    Footer: [
      <Fragment key={1}>
        {/* <span
          className={"mt-3"}
          key={111}
          style={{ color: "#7ED321", fontSize: "0.8em" }}
        >
          YOUR RESULTS
        </span>
        <span className={"mt-2 mb-3"} key={112}>
          Thanks for playing our online escape room.
          <br />
          We hope you had fun.
        </span> */}
      </Fragment>,
      <span key={2}></span>,

      <span key={4} className={"mt-3"}>
        If you want to continue solving the challenges you haven't solved yet,
        press the Continue Playing button.
        <br />
        This won't change your results.
      </span>,
      <span key={3} className={"mt-2"}>
        Visit our website to discover more fun experiences.
      </span>,
    ],
  },
];
