import React, { Fragment } from "react";
import { IImageWrapper } from "../imageMapper/imageMapper";
import { useResizeDetector } from "react-resize-detector";

const AreaPanel: React.FC<IImageWrapper> = ({
  dimensions,
  areas,
  viewerImage,
  onAreaClick,
  style,
}) => {
  const [getCoords, setCoords] = React.useState<any[]>([
    { x1: 0, y1: 0, x2: 0, y2: 0 },
  ]);
  const [getCoordString, setCoordString] = React.useState<string[]>([]);

  const onResize = React.useCallback((width, height) => {
    if (width && height) onImageLoaded(width, height);
  }, []);
  const { ref } = useResizeDetector({ onResize });

  const getWidthPoint = (originalPoint: number, newWidth: number) => {
    const cal = (originalPoint / dimensions[0]) * 100;
    const newCal = (cal * newWidth) / 100;
    const fixedValue = newCal.toFixed(0);
    return parseInt(fixedValue);
  };

  const getHeightPoint = (originalPoint: number, newHeight: number) => {
    const cal = (originalPoint / dimensions[1]) * 100;
    const newCal = (cal * newHeight) / 100;
    const fixedValue = newCal.toFixed(0);
    return parseInt(fixedValue);
  };
  const onImageLoaded = (width: number, height: number) => {
    // const height = image.currentTarget.offsetHeight;
    // const width = image.currentTarget.offsetWidth;
    const newAreas: any = [];
    const newAreaString: string[] = [];
    areas.forEach((area) => {
      const points = area.coords;
      const newCoords = {
        x1: getWidthPoint(points[0], width),
        y1: getHeightPoint(points[1], height),
        x2: getWidthPoint(points[2], width),
        y2: getHeightPoint(points[3], height),
      };
      newAreas.push(newCoords);
      newAreaString.push(
        `${newCoords.x1},${newCoords.y1},${newCoords.x2},${newCoords.y2}`
      );
    });
    setCoords([...newAreas]);
    setCoordString([...newAreaString]);
  };

  return (
    <div
      ref={ref}
      style={{
        minWidth: "25%",
        maxWidth: "100%",
        position: "relative",
      }}
    >
      <img
        src={viewerImage}
        alt=""
        style={{ ...style }}
        useMap="#workmap"
      ></img>
      {getCoordString.map((_, index) => {
        return (
          <Fragment key={index}>
            {areas[index].link ? (
              <div
                style={{
                  position: "absolute",
                  width: getCoords[index].x2 - getCoords[index].x1,
                  height: getCoords[index].y2 - getCoords[index].y1,
                  backgroundColor: areas[index].color,
                  top: getCoords[index].y1,
                  left: getCoords[index].x1,
                  cursor: `${
                    areas[index].pointerCursor ? "pointer" : "default"
                  }`,
                }}
                onClick={() => {
                  onAreaClick(index);
                }}
              ></div>
            ) : null}
            {areas[index].color ? (
              <div
                style={{
                  position: "absolute",
                  width: getCoords[index].x2 - getCoords[index].x1,
                  height: getCoords[index].y2 - getCoords[index].y1,
                  backgroundColor: areas[index].color,
                  top: getCoords[index].y1,
                  left: getCoords[index].x1,
                }}
              ></div>
            ) : null}
            {areas[index].image ? (
              <img
                src={areas[index].image}
                alt="light"
                style={{
                  position: "absolute",
                  width: getCoords[index].x2 - getCoords[index].x1,
                  height: getCoords[index].y2 - getCoords[index].y1,
                  backgroundColor: areas[index].color,
                  top: getCoords[index].y1,
                  left: getCoords[index].x1,
                }}
              ></img>
            ) : null}
          </Fragment>
        );
      })}
      {/* <map name="workmap">
        {getCoordString.map((coords, index) => {
          return (
            <area
              key={index}
              shape="rect"
              coords={coords}
              alt="paint"
              style={{
                cursor: `${areas[index].pointerCursor ? "pointer" : "default"}`,
              }}
              onMouseEnter={() => {
                console.log("Enter");
              }}
              onClick={() => {
                onAreaClick(index);
              }}
            ></area>
          );
        })}
      </map> */}
    </div>
  );
};

export default AreaPanel;
