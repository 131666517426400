import { IPopContent, PopupContent } from "./TTJr";
import { GameTypes, EGameCompany } from "../../container/Enums/Enums";
import React from "react";

const GetGameMessage = (type: string): IPopContent[] | null => {
  switch (type) {
    case GameTypes.TTJr:
      return PopupContent;
    default:
      return null;
  }
};
export const GetMessage = (
  gameType: string,
  companyName: string,
  messageType: "success" | "failure",
  stepNo: string
) => {
  const content = GetGameMessage(gameType);
  if (content && companyName === EGameCompany.Reckitt) {
    return GetActionMessage(content, messageType, stepNo);
  }
  return GetActionMessage(null, messageType, stepNo);
};
const GetActionMessage = (
  content: IPopContent[] | null,
  messageType: "success" | "failure",
  stepNo: string
) => {
  const step = parseInt(stepNo);
  if (messageType === "success") {
    return content
      ? content[step - 1].Success
      : [<span key={1}>The answer is correct! Great work team!</span>];
  }
  return content
    ? content[step - 1].Failure
    : [
        <span key={1}>
          The answer is incorrect and your team received a 30 second penalty.
        </span>,
        <span key={2}>Have another look and try again.</span>,
      ];
};
