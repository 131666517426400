import { colors } from "@material-ui/core";
import React, { PropsWithChildren } from "react";
import classes from "./infoslot.module.scss";

interface IInfoSlot extends PropsWithChildren<any> {
  color?: string;
  style: React.CSSProperties;
  title: string;
  titleStyle?: React.CSSProperties;
  message: string;
  messageStyle?: React.CSSProperties;
}

const defaultProps: IInfoSlot = {
  color: "",
  style: {},
  title: "",
  titleStyle: { fontSize: "1.8em" },
  message: "",
  messageStyle: { fontSize: "0.7em" },
};
const InfoSlot: React.FC<IInfoSlot> = (props) => {
  const className: string = props.color
    ? "info-container-" + props.color
    : "info-container";
  return (
    <div className={classes[className]} style={{ ...props.style }}>
      <span className={classes["info-title"]} style={props.titleStyle}>
        {props.title}
      </span>
      <span className={classes["info-message"]} style={props.messageStyle}>
        {props.message}
      </span>
    </div>
  );
};

InfoSlot.defaultProps = defaultProps;
// export default InfoSlot;
export default React.memo(InfoSlot, function arePropsAreEqual(prev, next) {
  return prev.title === next.title && prev.message === next.message;
});
