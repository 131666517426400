import React, {
  PropsWithChildren,
  useState,
  useEffect,
  useContext,
  Fragment,
} from "react";
import { GAME_TIME } from "../../../constants";
import { GamestateContext } from "../../../context/GameState/GameStateContext";
import { Clamp } from "../../../utilities/util";
import CFontAwesomeIcon from "../../customfonticon/cfontawesomicon";
import classes from "./infoslot.module.scss";
import { BTGameContext } from "../../../context/BTGameContext/BTGameContext";

export const BGMusic =
  "https://storage.googleapis.com/virtual-escape-assets/MusicFiles/BGMusic.mp3";
export const Outro =
  "https://storage.googleapis.com/virtual-escape-assets/MusicFiles/Outro.mp3";

interface IAudioSlot extends PropsWithChildren<any> {
  path: string;
  style: React.CSSProperties;
}

const defaultProps: IAudioSlot = {
  style: {},
  path: "",
};
const AudioSlot: React.FC<IAudioSlot> = ({ style, path }) => {
  const { gameTime } = useContext(GamestateContext);
  const [sliderValue, setSliderValue] = useState<number>(100);
  const { isBGAudioPlaying, updateIsBGAudioPlaying, isBtGameStep4 } =
  useContext(BTGameContext);
  const [isPlaying, setPlaying] = useState<boolean>(isBGAudioPlaying);
  const [audio, setAudioClip] = useState({
    currentTime: 0,
    clip: new Audio(path),
    path: path,
  });
  const currentTimer = React.useRef<number>(0);

  useEffect(() => {
    setPlaying(isBGAudioPlaying);
  }, [isBGAudioPlaying]);

  useEffect(() => {
    updateIsBGAudioPlaying(isPlaying);
    if (isPlaying) {
      audio.clip.play();
      const currentTime = Clamp(gameTime, 0, GAME_TIME);
      if (currentTime > 0) restartPlay();
    } else {
      audio.clip.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    currentTimer.current = gameTime;
  }, [gameTime]);

  const className: string = "audio-container";

  useEffect(() => {
    if(isPlaying){
      restartPlay();
    }
  }, []);
  useEffect(() => {
    if(isPlaying){
    audio.clip.onloadedmetadata = () => {
      console.log("Current Time:", audio.currentTime);
      audio.clip.currentTime = audio.currentTime;
      audio.clip.volume = sliderValue / 100;
      audio.clip.play();
    };
    audio.clip.ontimeupdate = () => {};
    audio.clip.onended = () => {
      console.log(currentTimer.current);
      audio.currentTime = 0;
      //setPlaying(false);
      setAudioClip((prev) => {
        return {
          ...prev,
          currentTime: 0,
          clip: new Audio(getMusicPath()),
          path: getMusicPath(),
        };
      });
    };
    audio.clip.onerror = () => {
      console.log("Error on Loading Audio");
      setAudioClip((prev) => {
        return {
          ...prev,
          currentTime: 0,
          clip: new Audio(prev.path),
          path: prev.path,
        };
      });
    };
  }
  
    return () => {
      audio.clip.pause();
      audio.clip.onloadedmetadata = null;
      audio.clip.ontimeupdate = null;
    };
  }, [audio.clip]);

  const restartPlay = () => {
    const firstMusicDuration = GAME_TIME - 60 * 57;
    const lastMusicDuration = 60 * 3;
    const remainingTime = Clamp(gameTime, 0, GAME_TIME);
    console.log(remainingTime, firstMusicDuration);
    if (remainingTime === 0) {
      setAudioClip({
        currentTime: 0,
        clip: new Audio(path),
        path: path,
      });
    } else if (remainingTime < firstMusicDuration) {
      setAudioClip({
        currentTime: lastMusicDuration - remainingTime,
        clip: new Audio(Outro),
        path: Outro,
      });
    } else {
      setAudioClip({
        currentTime: Clamp(GAME_TIME - gameTime, 0, GAME_TIME),
        clip: new Audio(path),
        path: path,
      });
    }
  };

  const toggleAudioState = () => {
    if (!isBtGameStep4) {
      setPlaying(!isPlaying);
    }
  };

  const getMusicPath = () => {
    const remainingTime = Clamp(currentTimer.current, 0, GAME_TIME);
    if (remainingTime === 0) {
      return BGMusic;
    } else {
      return Outro;
    }
  };
  return (
    <Fragment>
      <div className={classes[className]} style={{ ...style }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              color: "red",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "5px",
            }}
          >
            <CFontAwesomeIcon
              parentIcon={"fas fa-circle"}
              childIcon={isPlaying ? "fas fa-pause" : "fas fa-play"}
              style={{
                fontSize: "1.8em",
              }}
              childStyle={{ fontSize: "0.28em" }}
              onClick={() => {
                toggleAudioState();
              }}
            ></CFontAwesomeIcon>
          </div>
          <span
            className={`${classes["info-message"]}`}
            style={{ fontSize: "0.7em" }}
          >
            MUSIC
          </span>
        </div>
        <input
          // className={classes["slide-bar"]}
          style={{
            background: `linear-gradient(to right, #F60F0C 0%, #F60F0C ${sliderValue}%, #F79796 ${sliderValue}%, #F79796 100%)`,
            transform: "rotate(-90deg)",
            width: "68px",
            position: "absolute",
            right: 0,
          }}
          type="range"
          min={0}
          max={100}
          value={Math.ceil(sliderValue)}
          onChange={(e) => {
            setSliderValue(parseInt(e.target.value));
            audio.clip.volume = parseInt(e.target.value) / 100;
          }}
        />
      </div>
    </Fragment>
  );
};

AudioSlot.defaultProps = defaultProps;
// export default InfoSlot;
export default React.memo(AudioSlot, function arePropsAreEqual(prev, next) {
  return prev.path === next.path;
});
