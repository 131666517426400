import React, { Fragment, useContext, useEffect, useState } from "react";
import { GamestateContext } from "../../context/GameState/GameStateContext";
import { AuthContext } from "../../context/Auth/AuthContext";
import { withRouter, RouteComponentProps } from "react-router";
import { NavLink } from "react-router-dom";
import logo_experios from "../../assets/Logo/logo-expressio.svg";
import logo_reckitt from "../../assets/Logo/Reckitt.svg";
import logo_enigma from "../../assets/Logo/Enigma_Escapes_Logo_white.svg";
import logo_fun_empire from "../../assets/Logo/fun-empire-logo.png";
import logo_virtual_escape from "../../assets/Logo/logo-virtual-escape.png";
import logo_escape_hq from "../../assets/Logo/HQ_LOGO.png";
import escape_room_for_you_logo from "../../assets/Logo/escape-rooms-for-you-logo.png";
import get_me_out_logo from "../../assets/Logo/Get_Me_Out_Logo.png";
import grand_escape_game_logo from "../../assets/Logo/Grand_Escape_Game_Logo.png";
import "./navbar.scss";
import pusher from "../../config/pusher";
import { Clamp } from "../../utilities/util";
import { getMessageBox } from "../game/questionpanel/choosepanel";
import { PopupContext } from "../../context/Popup/PopupContext";
import { getTranslatedWord } from "../../metadata/ButtonText";
import { getTranslatedPhrase } from "../../metadata/ErrorText";
import {
  EGameCompany,
  EGameScreen,
  EMessageType,
} from "../../container/Enums/Enums";
import {
  CHANNEL_JOIN_FAILURE,
  CHANNEL_JOIN_SUCCESS,
  MAX_USERS,
  MEMBER_JOIN,
  MEMBER_REMOVE,
  ROUTE_MENU,
  ROUTE_GAME_RULES,
  START_GAME,
  GAME_ERROR,
  GAME_COMPANY,
} from "../../constants";
import Button from "../button/button";

const Navbar: React.FC<RouteComponentProps> = ({ history }) => {
  const {
    logoutUser,
    currentOnlineUser,
    isAuthenticated,
    UPDATE_ONLINE_USER,
    checkAuthentication,
  } = useContext(AuthContext);

  const { reset, screen, gameCode, gameLanguage } =
    useContext(GamestateContext);

  const { showPopup, popup, showLoading } = useContext(PopupContext);

  const [hideButton, setHideButton] = useState<boolean>(false);
  const [hideLogo, setHideLogo] = useState<boolean>(false);
  const [logoutText, setLogoutText] = useState<string>("LOG OUT");

  useEffect(() => {
    console.log(screen);
    switch (screen) {
      case EGameScreen.ResultViewer:
      case EGameScreen.Login:
        setHideLogo(true);
        setHideButton(true);
        break;
      case EGameScreen.Result:
      case EGameScreen.Intro:
        setHideButton(false);
        setLogoutText("EXIT GAME");
        break;
      case EGameScreen.Question:
        setHideButton(false);
        setLogoutText("EXIT GAME");
        break;
      default:
        setHideLogo(false);
        setHideButton(false);
        setLogoutText("LOG OUT");
        break;
    }
  }, [screen]);
  useEffect(() => {
    if (checkAuthentication() && gameCode !== "") {
      let memberChannel = pusher.subscribe(`presence-${gameCode}`);

      memberChannel.bind(CHANNEL_JOIN_SUCCESS, () => {
        let members = memberChannel;
        let memberInfo: any = {};
        Object.assign(memberInfo, members);
        let totalMember = memberInfo.members.count;
        if (totalMember > MAX_USERS) {
          showErrorMessage(
            <span>
              The maximum number of players is already logged in. Please try
              logging in with a different game code.
            </span>
          );
          logoutUser();
          return;
        }
        UPDATE_ONLINE_USER(totalMember);
      });

      memberChannel.bind(CHANNEL_JOIN_FAILURE, (err: any) => {
        let { status } = err;
        if (status === 408 || status === 503) {
          // retry?
          memberChannel = pusher.subscribe(`presence-${gameCode}`);
        }
      });
      memberChannel.bind(MEMBER_JOIN, () => {
        let members = memberChannel;
        let memberInfo: any = {};
        Object.assign(memberInfo, members);
        let totalMember = memberInfo.members.count;
        UPDATE_ONLINE_USER(totalMember);
      });

      memberChannel.bind(MEMBER_REMOVE, () => {
        let members = memberChannel;
        let memberInfo: any = {};
        Object.assign(memberInfo, members);
        let totalMember = memberInfo.members.count;
        UPDATE_ONLINE_USER(totalMember);
      });

      memberChannel.bind(START_GAME, () => {
        let members = memberChannel;
        let memberInfo: any = {};
        Object.assign(memberInfo, members);
        let totalMember = memberInfo.members.count;
        UPDATE_ONLINE_USER(totalMember);
      });

      return () => {
        // pusher.unsubscribe("12345678");
        memberChannel.unbind_all();
        console.log("Unmounted Navbar");
      };
    }
  }, [gameCode, isAuthenticated]);

  useEffect(() => {
    let isMount = true;
    if (isMount) {
      let memberChannel = pusher.subscribe(gameCode);
      memberChannel.bind(CHANNEL_JOIN_SUCCESS, () => {});

      memberChannel.bind(CHANNEL_JOIN_FAILURE, (err: any) => {
        let { status } = err;
        if (status === 408 || status === 503) {
          // retry?
          console.error("Pusher not Subscribed on channel:" + gameCode);
          memberChannel = pusher.subscribe(gameCode);
        }
      });

      memberChannel.bind(GAME_ERROR, (data: any) => {
        showErrorMessage(
          <span>There is an error while processing your request.</span>
        );
        showLoading(false);
      });

      return () => {
        isMount = false;
        //pusher.unsubscribe(gameCode);
        memberChannel.unbind_all();
      };
    }
  }, [gameCode, isAuthenticated]);

  const showErrorMessage = (message: React.ReactNode) => {
    let box = getMessageBox(
      EMessageType.Error,
      "Something went wrong",
      [message],
      <Button
        text={"CLOSE"}
        style={{ width: "235px", height: "53px", fontSize: "1em" }}
        onClick={() => showPopup(null)}
        autoFocus={true}
      ></Button>
    );
    showPopup(box);
  };
  const onLogoutButtonClicked = () => {
    switch (screen) {
      case EGameScreen.Result:
      case EGameScreen.Intro:
      case EGameScreen.Question:
        history.replace(ROUTE_MENU);
        break;
      default:
        logoutUser();
        reset();
        break;
    }
  };
  const onConnectionButtonClicked = (): void => {
    let box = getMessageBox(
      EMessageType.Info,
      "Game Code",
      [
        <span>
          {`${getTranslatedPhrase(
            "You are logged in with game code:",
            gameLanguage
          )} ${gameCode}.`}
        </span>,
      ],
      <Button
        text={"CLOSE"}
        style={{ width: "235px", height: "53px", fontSize: "1em" }}
        onClick={() => showPopup(null)}
        autoFocus={true}
      ></Button>
    );
    showPopup(box);
  };

  const OnGameRuleButtonClick = () => {
    history.replace(ROUTE_GAME_RULES);
  };

  const navBarLogo = () => {
    const companyLogo = getCompanyLogo();
    const virtualEscapeLogo = getVirtualEscapeLogo();
    return (
      <span className="custom-margin">
        <div className="navbar-brand ">{companyLogo}</div>
        {virtualEscapeLogo === "" ? null : (
          <div className="navbar-brand">
            <img
              src={virtualEscapeLogo}
              height="28"
              alt="Logo Virtual Escape"
            />
          </div>
        )}
      </span>
    );
  };
  const navBarButton = () => {
    return (
      <Fragment>
        {popup ? popup : null}
        <button
          type="button"
          className="navbar-toggler"
          data-toggle="collapse"
          data-target="#navbarCollapse"
          aria-expanded="false"
          aria-controls="navbarCollapse"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ml-auto">
            {screen === EGameScreen.Question ? (
              <div className="nav-item mr-2">
                <button
                  type="button"
                  className="btn btn-light nav-item-custom-btn button"
                  onClick={() => {
                    OnGameRuleButtonClick();
                  }}
                >
                  <i className="far fa-question-circle"></i>&nbsp;{" "}
                  {getTranslatedWord("HOW TO PLAY", gameLanguage)}
                </button>
              </div>
            ) : null}
            <div className="nav-item">
              <button
                type="button"
                className="btn btn-light nav-item-custom-btn button"
                onClick={() => {
                  onConnectionButtonClicked();
                }}
              >
                <i className="fal fa-users-class"></i> &nbsp;{" "}
                {Clamp(currentOnlineUser, 0, MAX_USERS)}{" "}
                {getTranslatedWord("CONNECTED", gameLanguage)}
              </button>
            </div>
          </div>

          <div className="navbar-nav ">
            <NavLink to="" className="nav-item nav-link">
              <button
                type="button"
                className="btn btn-light nav-item-custom-btn button"
                onClick={() => {
                  onLogoutButtonClicked();
                }}
              >
                <i className="nav-item-custom-btn-img far fa-sign-out"> </i>
                &nbsp; {getTranslatedWord(logoutText, gameLanguage)}
              </button>
            </NavLink>
          </div>
        </div>
      </Fragment>
    );
  };
  return (
    <nav className="navbar navbar-expand-md navbar-dark bg-dark custom mt-2">
      {hideLogo ? null : navBarLogo()}
      {hideButton ? null : navBarButton()}
    </nav>
  );
};

export default withRouter(Navbar);

const getCompanyLogo = (): React.ReactNode => {
  const company = localStorage.getItem(GAME_COMPANY) || "";
  switch (company) {
    case EGameCompany.Experios:
      return <img src={logo_experios} height="28" alt="Logo Experios" />;
    case EGameCompany.Reckitt:
      return <img src={logo_reckitt} height="56" alt="Logo Reckitt" />;
    case EGameCompany.Enigma:
      return <img src={logo_enigma} height="28" alt="Logo Enigma" />;
    case EGameCompany.FunEmpire:
      return <img src={logo_fun_empire} height="28" alt="Logo Fun Empire" />;
    case EGameCompany.EscapeHQ:
      return <img src={logo_escape_hq} height="56" alt="Escape HQ" />;
    case EGameCompany.GetMeOut:
      return <img src={get_me_out_logo} height="48" alt="Escape HQ" />;
    case EGameCompany.GrandEscapeGame:
      return <img src={grand_escape_game_logo} height="62" alt="Escape HQ" />;
    case EGameCompany.EscapeRoom:
      return (
        <img
          src={escape_room_for_you_logo}
          height="75"
          alt="Logo Escape Room"
        />
      );
    default:
      return <img src={logo_experios} height="28" alt="Logo Experios" />;
  }
};

const getVirtualEscapeLogo = (): string => {
  const company = localStorage.getItem(GAME_COMPANY) || "";
  switch (company) {
    case EGameCompany.Experios:
      return logo_virtual_escape;
    case EGameCompany.EscapeHQ:
    case EGameCompany.FunEmpire:
    case EGameCompany.Enigma:
    case EGameCompany.EscapeRoom:
    case EGameCompany.GetMeOut:
    case EGameCompany.GrandEscapeGame:
    case EGameCompany.Reckitt:
      return "";
    default:
      return logo_virtual_escape;
  }
};
