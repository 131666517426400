import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Radio, { RadioProps } from "@material-ui/core/Radio";

const WhiteRadio = withStyles({
  root: {
    color: "#fff",
    "&$checked": {
      color: "#fff",
    },
  },
  checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

interface ICheckbox extends RadioProps {
  label: string;
}

const RadioButton: React.FC<ICheckbox> = ({
  label,
  onChange,
  checked,
  value,
}) => {
  return (
    <label className={`mr-3`}>
      <WhiteRadio value={value} checked={checked} onChange={onChange} />
      {label}
    </label>
  );
};

export default RadioButton;
