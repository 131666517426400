import React, { useState } from "react";
import { AuthProvider } from "./AuthContext";
import { withRouter, RouteComponentProps } from "react-router";
import { AuthStateInterface } from "./AuthInterface";
import { GAME_CODE, GAME_COMPANY, GAME_TYPE, TOKEN } from "../../constants";
import pusher from "../../config/pusher";

const AuthContextContainer: React.FunctionComponent<RouteComponentProps> = ({
  history,
  children,
}) => {
  const defaultAuthState: AuthStateInterface = {
    isAuthenticated: false,
    token: "",
    currentOnlineUser: 0,
  };

  const [state, setState] = useState<AuthStateInterface>(defaultAuthState);

  const authenticateUser: (token: string) => void = (token: string): void => {
    localStorage.setItem(TOKEN, token);
    setState((prev) => {
      return {
        ...prev,
        isAuthenticated: true,
        token: token,
      };
    });
  };

  const logoutUser: () => void = (): void => {
    let code = localStorage.getItem(GAME_CODE);

    pusher.unsubscribe(`presence-${code}`);
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(GAME_CODE);
    localStorage.removeItem(GAME_TYPE);
    localStorage.removeItem(GAME_COMPANY);
    //pusher.disconnect();

    setState((prev) => {
      return {
        ...prev,
        isAuthenticated: false,
      };
    });
  };

  const checkAuthentication: () => boolean = (): boolean => {
    return !!localStorage.getItem(TOKEN);
  };

  const UPDATE_ONLINE_USER: (totalUserOnline: number) => void = (
    totalUserOnline: number
  ): void => {
    setState((prev) => {
      return {
        ...prev,
        currentOnlineUser: totalUserOnline,
      };
    });
  };

  return (
    <AuthProvider
      value={{
        isAuthenticated: state.isAuthenticated,
        currentOnlineUser: state.currentOnlineUser,
        authenticateUser,
        logoutUser,
        checkAuthentication,
        UPDATE_ONLINE_USER,
      }}
    >
      {children}
    </AuthProvider>
  );
};

export default withRouter(AuthContextContainer);
