import React, { useContext } from "react";
import { GamestateContext } from "../../context/GameState/GameStateContext";
import { SecToMin } from "../../utilities/util";
import classes from "./modelviewer.module.scss";

interface IModelViewer {
  path: string;
  zoomValue?: number;
  isDynamic?: boolean;
  style?: React.CSSProperties;
  footerText?: string;
  exposure?:number;
  poster?:string
}
interface IModelProps {
  src: string;
  class: string;
  "camera-controls": boolean;
  "camera-orbit": string;
  exposure: number;
  poster:string
}

const ModelViewer: React.FC<IModelViewer> = ({
  path,
  zoomValue = 2,
  isDynamic,
  style,
  footerText,
  exposure=0.28,
  poster=""
}) => {
  const { gameTime } = useContext(GamestateContext);
  const getPathToModel = (): string => {
    if (isDynamic) {
      // const url = `https://storage.googleapis.com/virtual-escape-assets/GameSteps/TT/BombTimer/${SecToMin(
      //   gameTime
      // )}-01-min.png`;
      return `${process.env.PUBLIC_URL}/assests/GameFiles/BombTimer/${SecToMin(
        gameTime
      )}.png`;
    }
    return path;
  };
  let props: IModelProps = {
    src: `${process.env.PUBLIC_URL
      }/assests/GameFiles/Models/${getPathToModel()}`,
    class: classes["model-viewer"],
    "camera-controls": true,
    "camera-orbit": `0deg 45deg ${zoomValue}m`,
    exposure:exposure,
    poster:poster

    
  };
  return (
    <div className={classes["model-viewer-wrapper"]} style={{ ...style }}>
      {React.createElement("model-viewer", props)}
      {footerText && <div className={classes.footer}>{footerText}</div>}
    </div>
  );
};

export default ModelViewer;
