export interface AuthInterface {
  isAuthenticated: boolean;
  currentOnlineUser: number;
  authenticateUser: (token: string) => void;
  logoutUser: () => void;
  checkAuthentication: () => boolean;
  UPDATE_ONLINE_USER: (totalUserOnline: number) => void;
}

export const defaultAuthContext: AuthInterface = {
  isAuthenticated: false,
  currentOnlineUser: 0,
  authenticateUser: (token: string) => {},
  logoutUser: () => {},
  checkAuthentication: () => false,
  UPDATE_ONLINE_USER: (totalUserOnline: number) => {},
};

export interface AuthStateInterface {
  isAuthenticated: boolean;
  token: string;
  currentOnlineUser: number;
}
