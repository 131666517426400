import React, { Fragment } from "react";
import ImageViewer from "../components/game/imagepanel/imagepanel";
import { GameLanguages } from "../container/Enums/Enums";
import { IResult } from "./TTResult";
import { StringToNumber } from "../utilities/util";
import { BTGameStepFolder } from "../constants";

export const getGameResultByLanguage = (language: string): IResult[] => {
  switch (language) {
    case GameLanguages.ENUK:
      return Result;
    case GameLanguages.DUTCH:
      return Result;
    default:
      return Result;
  }
};

export const getResultByStep = (gameStepNo: string): number => {
  const stepNo = StringToNumber(gameStepNo);
  // if (stepNo === 13) {
  //   return 0;
  // }
  return 0;
};

const Result: IResult[] = [
  {
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "1.2em" }}>
        SUCCESS
      </span>,
      <span key={2} className={"mt-2"}>
        Look at all that shining gold!
      </span>,
      <span key={3} className={"mt-3"}>
        That was abso-loot-ly one great last adventure.<br></br>With yer help,
        me loot's safe and sound.
        <br></br>It's time to hang up me hat and count me loot, all thanks to
        yer cunning and wit.
      </span>,
      <span key={4} className={"mt-3"}>
        Shiver me timbers! Whether on sea or land, there be tales waitin' to be
        told.<br></br>Keep yer compass close and yer treasures closer!
      </span>,
      <span key={5} className={"mt-3"}>
        Thanks a bounty for joinin’me on this grand escapade!
        <br></br>Until our next quest, keep sailing and searching! Safe travels,
        ye landlubbers!
      </span>,
      <ImageViewer
        key={6}
        linkImage={BTGameStepFolder + "Success.jpg"}
        viewerImage={BTGameStepFolder + "Success.jpg"}
        defaultScale={1}
        footerText=""
        style={{ maxWidth: "60%", minWidth: "40%" }}
      ></ImageViewer>,
    ],
    TeamInfo: [
      <span key={7} style={{ color: "#7ED321", fontSize: "1.2em" }}>YOUR RESULTS</span>,
      <span key={8} className="mt-2">
        Thank you for playing our online escape room.
        <br />
        We hope you had fun! Here are your results.
      </span>,
    ],
    Content: BTGameStepFolder + "Success.jpg",
    Footer: [
      <Fragment key={9}></Fragment>,

      <span key={10}></span>,
      <span className={"mt-3"} key={11}>
        Visit our website to discover more fun experiences.
      </span>,
    ],
  },
];
