import { FriendlyShips,EnemyShips } from "./container/Enums/Enums";

// Application Constants
export const TOKEN = "AUTH_TOKEN";
export const AUTH_TOKEN_HEADER = "x-auth-token";
export const GAME_CODE = "GAME_CODE";
export const GAME_TYPE = "GAME_TYPE";
export const GAME_COMPANY = "COMPANY";
export const GAME_LANGUAGE = "GAME_LANGUAGE";
export const GAME_TIME = 3600; // Game Time in secs
export const MAX_USERS = 7; // Max user Join on same Game Code
export const HINT_AVAILABILITY = 60; // hint will be available after 2 minutes- 2 minutes = 120s
export const MAX_REQUESTED_HINT = 4; // Max requested hint count = 4 for 1 step
export const LAST_GAME_STEP = "13";
export const WEBSITE_LINK = "https://www.experios.fun";
export const WEBSITE_LINK_NL = "https://www.virtualescaperooms.nl";
export const ENIGMA_WEBSITE_LINK = "https://www.enigmaescapes.com";
export const FUN_EMPIRE_WEBSITE_LINK =
  "https://www.thefunempire.com/virtual-team-building/";
export const ESCAPE_ROOM_WEBSITE_LINK = "https://escaperoomsforyou.co.uk/";
export const ESCAPE_HQ_WEBSITE_LINK = "https://www.escapehq.co.nz";
export const ESCAPE_GET_ME_OUT_LINE =
  "https://www.getmeoutescape.com/online-experiences";
// API Constants
export const GRAND_ESCAPE_GAME_LINK =
  "https://www.funtaniaorlando.com/grand-escape-game";
export const LOGIN = "login";
export const HEALTH_CHECK = "healthcheck";
export const CHANNEL_MEMBER = "pusher/channel_member";
export const GET_GAME_STATUS = "getgamestatus";
export const START_GAME_API = "startgame";
export const SUBMIT_ANSWER_API = "submitanswer";
export const REQUEST_HINT_API = "requesthint";
export const LEADERBOARD_API = "leaderboard";
export const GET_CODE_RESULT = "getresult";
export const REQUEST_RELOAD = "continueplaying";
export const IMAGE_LYRICS =
  "What shall we do with the drunken sailor What shall we do with the drunken sailor What shall we do with the drunken sailor Early in the morning Hooray and up she rises Hooray and up she rises Hooray and up she rises Early in the morning";
export const LYRICS_MATCHING_PERCENTAGE = 30;
export const BT_STEP7_LINK = "https://view.genial.ly/65c45240edbeb50014b07de1";
export const FRIENDLY_SHIPS = [FriendlyShips.Portuguese, FriendlyShips.French];
export const ENEMY_SHIPS = [EnemyShips.English, EnemyShips.Dutch, EnemyShips.Spanish];

// Routes Constant
export const ROUTE_LOGIN = "/game-login";
export const ROUTE_LOGIN_NL = "/nl";
export const ROUTE_ERROR = "/error";
export const ROUTE_MENU = "/";
export const ROUTE_GAME_RULES = "/game-rules";
export const ROUTE_START_INTRO = "/game-start-intro";
export const ROUTE_TEAM_NAME = "/game-team-name";
export const ROUTE_RESUME_INTRO = "/game-resume-intro";
export const ROUTE_GAME_STEP = "/game-play";
export const ROUTE_RESULT = "/game-result";
export const ROUTE_LEADERBOARD = "/game-leaderboard";
export const ROUTE_HOW_TO_PLAY = "/how-to-play";
export const ROUTE_CODE_RESULT_VIEWER = "/results";

//PusherConstants
export const CHANNEL_JOIN_SUCCESS = "pusher:subscription_succeeded";
export const CHANNEL_JOIN_FAILURE = "pusher:subscription_error";
export const MEMBER_JOIN = "pusher:member_added";
export const MEMBER_REMOVE = "pusher:member_removed";
export const START_GAME = "game-started";
export const SUBMIT_ANSWER = "answer-submitted";
export const REQUEST_HINT = "hint-requested";
export const GAME_ENDED = "game-ended";
export const GAME_ERROR = "game-error";
export const JUMP_END = "jump-to-end";

//
export const BTBackgroundFolder =
  "https://storage.googleapis.com/virtual-escape-assets/Backgrounds/BT/";
export const BTGameStepFolder =
  "https://storage.googleapis.com/virtual-escape-assets/GameSteps/BT/";
