import React, { Fragment, useContext, useEffect, useState } from "react";
import VideoPlayer from "../videoPlayer/videoPlayer";
import { BTGameContext } from "../../../context/BTGameContext/BTGameContext";

const Step2Mapper: React.FC = () => {
  const { ingredientVideo, playAgainIngredientVideo } =
    useContext(BTGameContext);
  const [videoKey, setVideoKey] = useState(1);

  useEffect(() => {
    setVideoKey((prevKey: any) => prevKey + 1);
  }, [ingredientVideo, playAgainIngredientVideo]);

  return (
    <Fragment>
      <VideoPlayer key={videoKey} videoUrl={ingredientVideo} />
    </Fragment>
  );
};

export default Step2Mapper;
