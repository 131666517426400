import React, { useState, useEffect } from "react";
import CFontAwesomeIcon from "../../customfonticon/cfontawesomicon";
import classes from "./recordingPanel.module.scss";
import { styleTime } from "../../../utilities/util";

export interface IRecording {
  header: string;
  path: string;
  hasSlider?: boolean;
}
const Recording: React.FC<IRecording> = ({ header, path, hasSlider }) => {
  const [isPlaying, setPlaying] = useState<boolean>(false);
  const [totalDuration, setTotalDuration] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [sliderValue, setSliderValue] = useState<number>(0);
  const [audio, setAudioClip] = useState(
    new Audio(`${process.env.PUBLIC_URL}/${path}`)
  );

  const soundPlayedPercentage = () => {
    if (audio) {
      let value = (audio.currentTime / audio.duration) * 100;
      return Math.ceil(value) + 0.5;
    } else {
      return 0;
    }
  };

  const getSlider = () => {
    if (hasSlider) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            textAlign: "center",
            width: "100%",
          }}
        >
          <input
            className={classes["slide-bar"]}
            style={{
              background: `linear-gradient(to right, #F60F0C 0%, #F60F0C ${soundPlayedPercentage()}%, #F79796 ${soundPlayedPercentage()}%, #F79796 100%)`,
            }}
            type="range"
            min={0}
            max={audio.duration ? audio.duration : "100"}
            value={Math.ceil(sliderValue)}
            onChange={(e) => {
              setSliderValue(parseInt(e.target.value));
              audio.currentTime = parseInt(e.target.value);
            }}
          />
        </div>
      );
    }
    return null;
  };
  const toggleAudioState = () => {
    try {
      if (!isPlaying) {
        audio.play();
      } else {
        audio.pause();
      }
      setPlaying(!isPlaying);
    } catch (err) {}
  };

  useEffect(() => {
    audio.onloadedmetadata = () => {
      setTotalDuration(audio.duration);
    };
    audio.ontimeupdate = () => {
      setCurrentTime(audio.currentTime);
      setSliderValue(audio.currentTime);
    };
    audio.onended = () => {
      audio.currentTime = 0;
      setPlaying(false);
    };
    audio.onerror = () => {
      console.log("Error on Loading Audio");
      setAudioClip(new Audio(`${process.env.PUBLIC_URL}/${path}`));
    };
    return () => {
      audio.pause();
      audio.onloadedmetadata = null;
      audio.ontimeupdate = null;
    };
  }, [audio]);

  return (
    <div className={classes["audio-container"]}>
      <span style={{ fontSize: "0.8em" }}>{header}</span>
      <div
        className={`${classes["audio-bar"]}`}
        style={{
          backgroundColor: `${!hasSlider ? "#F53B39" : ""}`,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            textAlign: "center",
            width: "40%",
          }}
        >
          <CFontAwesomeIcon
            parentIcon={"fas fa-circle"}
            childIcon={isPlaying ? "fas fa-pause" : "fas fa-play"}
            style={{
              fontSize: "2.5em",
            }}
            childStyle={{ fontSize: "0.35em" }}
            onClick={() => {
              toggleAudioState();
            }}
          ></CFontAwesomeIcon>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          fontSize: "0.8em",
          color: "white",
          marginTop: "5px",
        }}
      >
        <span style={{ width: "35%" }}>
          {isNaN(audio.currentTime) ? "00:00" : styleTime(currentTime)}
        </span>
        <span style={{ width: "35%" }}>
          {isNaN(audio.duration) ? "00:00" : styleTime(audio.duration)}
        </span>
      </div>
      {getSlider()}
    </div>
  );
};

//export default Recording;
export default React.memo(Recording, function arePropsAreEqual(prev, next) {
  return prev.path === next.path;
});
