import React, { useEffect, useState } from "react";
import { styleTime } from "../../../utilities/util";
import CFontAwesomeIcon from "../../customfonticon/cfontawesomicon";
import { IRecording } from "../recordings/recordingPanel";
import classes from "./imagepanel.module.scss";

const AudioClip: React.FC<IRecording> = ({ path }) => {
  const [isPlaying, setPlaying] = useState<boolean>(false);
  const [totalDuration, setTotalDuration] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [audio, setAudioClip] = useState(
    new Audio(`${process.env.PUBLIC_URL}/${path}`)
  );

  const toggleAudioState = (): void => {
    try {
      if (!isPlaying) {
        audio.play();
      } else {
        audio.pause();
      }
      setPlaying(!isPlaying);
    } catch (err) {}
  };

  useEffect(() => {
    audio.onloadedmetadata = () => {
      setTotalDuration(audio.duration);
    };
    audio.ontimeupdate = () => {
      setCurrentTime(audio.currentTime);
    };
    audio.onerror = () => {
      console.log("Error on Loading Audio");
      if (!(path === "" || path === undefined)) {
        setAudioClip(new Audio(`${process.env.PUBLIC_URL}/${path}`));
      }
    };
    return () => {
      audio.pause();
      audio.onloadedmetadata = null;
      audio.ontimeupdate = null;
    };
  }, [audio]);

  return (
    <div hidden={path === "" || path === undefined ? true : false}>
      <div className={`${classes["audio-bar"]}`}>
        <div
          style={{
            position: "absolute",
          }}
        >
          <CFontAwesomeIcon
            parentIcon={"fas fa-circle"}
            childIcon={isPlaying ? "fas fa-pause" : "fas fa-play"}
            style={{
              fontSize: "2em",
            }}
            childStyle={{ fontSize: "0.35em" }}
            onClick={() => {
              toggleAudioState();
            }}
          ></CFontAwesomeIcon>
        </div>
      </div>
      <div className={classes["audio-timer"]}>
        <span style={{ width: "35%" }}>
          {isNaN(audio.currentTime) ? "00:00" : styleTime(currentTime)}
        </span>
        <span style={{ width: "35%" }}>
          {isNaN(audio.duration) ? "00:00" : styleTime(audio.duration)}
        </span>
      </div>
    </div>
  );
};

//export default ImagePanel;

export default AudioClip;
