import React, { ReactNode } from "react";
export interface IPopContent {
  Success: ReactNode[];
  Failure: ReactNode[];
}
export const PopupContent: IPopContent[] = [
  {
    Success: [
      <span key={1}>
        The answer is correct!
        <br />
      </span>,
      <span key={2} style={{ color: "rgb(80, 162, 32)" }}>
        Similar to this challenge, a well-done investigation is required to
        determine the root cause and address proper corrective actions when a
        deviation occurs. <br />
      </span>,
      <span key={3}>Great work team!</span>,
    ],
    Failure: [
      <span key={1}>
        The answer is incorrect, and your team received a 30 second penalty.
        <br />
      </span>,
      <span key={2} style={{ color: "rgb(244, 29, 37)" }}>
        This is what happens when we do not investigate a deviation properly and
        do not eliminate the root cause. We have rework and losses. <br />
      </span>,
      <span key={3}>Have another look and try again.</span>,
    ],
  },
  {
    Success: [
      <span key={1}>
        The answer is correct!
        <br />
      </span>,
      <span key={2} style={{ color: "rgb(80, 162, 32)" }}>
        In Quality, SOPs, Policies, and Work Instructions are like a Tiramisu
        recipe. In order to have the same quality product, standard instructions
        must be followed. <br />
      </span>,
      <span key={3}>Great work team!</span>,
    ],
    Failure: [
      <span key={1}>
        The answer is incorrect, and your team received a 30 second penalty.
        <br />
      </span>,
      <span key={2} style={{ color: "rgb(244, 29, 37)" }}>
        It is for that reason that we need clear procedures and records. For
        example, describing the list of ingredients and preparation of a
        product. If you had clear instructions, you would know the answer.{" "}
        <br />
      </span>,
      <span key={3}>Have another look and try again.</span>,
    ],
  },
  {
    Success: [
      <span key={1}>
        The answer is correct!
        <br />
      </span>,
      <span key={2} style={{ color: "rgb(80, 162, 32)" }}>
        In Quality is also crucial registering the activities and keeping
        evidence. The evidence ensures the traceability of an event. <br />
      </span>,
      <span key={3}>Great work team!</span>,
    ],
    Failure: [
      <span key={1}>
        The answer is incorrect, and your team received a 30 second penalty.
        <br />
      </span>,
      <span key={2} style={{ color: "rgb(244, 29, 37)" }}>
        This is also what happens when we do not attach/provide the evidence of
        an action in QualityOne: The traceability is not possible. <br />
      </span>,
      <span key={3}>Have another look and try again.</span>,
    ],
  },
  {
    Success: [
      <span key={1}>
        The answer is correct!
        <br />
      </span>,
      <span key={2} style={{ color: "rgb(80, 162, 32)" }}>
        As per Good Documentation Practices, the document should be stored and
        identified accordingly to avoid misuse and errors. <br />
      </span>,
      <span key={3}>Great work team!</span>,
    ],
    Failure: [
      <span key={1}>
        The answer is incorrect, and your team received a 30 second penalty.
        <br />
      </span>,
      <span key={2} style={{ color: "rgb(244, 29, 37)" }}>
        In the ANZ SharePoint, obsolete and draft documents should be kept in
        folders identified as such. <br />
      </span>,
      <span key={3}>Have another look and try again.</span>,
    ],
  },
  {
    Success: [
      <span key={1}>
        The answer is correct!
        <br />
      </span>,
      <span key={2} style={{ color: "rgb(80, 162, 32)" }}>
        Data Integrity implies that the data should be Attributable, Legible,
        Contemporaneous, Original, and Accurate. Not sharing passwords of
        electronic systems is one way to keep the data attributable to the
        person executing the activity. <br />
      </span>,
      <span key={3}>Great work team!</span>,
    ],
    Failure: [
      <span key={1}>
        The answer is incorrect, and your team received a 30 second penalty.
        <br />
      </span>,
      <span key={2} style={{ color: "rgb(244, 29, 37)" }}>
        A record is considered to have integrity if it is complete and protected
        from unauthorized addition, deletion, alteration, use, and concealment.{" "}
        <br />
      </span>,
      <span key={3}>Have another look and try again.</span>,
    ],
  },
  {
    Success: [
      <span key={1}>
        The answer is correct!
        <br />
      </span>,
      <span key={2} style={{ color: "rgb(80, 162, 32)" }}>
        Having SOPs is only enough to achieve product/service quality if we have
        an effective training program in place. Training is essential to execute
        the steps described. <br />
      </span>,
      <span key={3}>Great work team!</span>,
    ],
    Failure: [
      <span key={1}>
        The answer is incorrect, and your team received a 30 second penalty.
        <br />
      </span>,
      <span key={2} style={{ color: "rgb(244, 29, 37)" }}>
        Without proper training, we increase the risk of errors when performing
        an activity. If you do not know how to execute the steps, you will not
        disarm the bomb safely. <br />
      </span>,
      <span key={3}>Have another look and try again.</span>,
    ],
  },
  {
    Success: [
      <span key={1}>
        The answer is correct!
        <br />
      </span>,
      <span key={2} style={{ color: "rgb(80, 162, 32)" }}>
        Risk management involves thinking about what could happen if someone is
        exposed to a hazard and how likely it is to happen. You should always
        aim to eliminate or mitigate risks. <br />
      </span>,
      <span key={3}>Great work team!</span>,
    ],
    Failure: [
      <span key={1}>
        The answer is incorrect, and your team received a 30 second penalty.
        <br />
      </span>,
      <span key={2} style={{ color: "rgb(244, 29, 37)" }}>
        The risks should be assessed, and mitigation actions should be taken to
        reduce the chances of cutting the incorrect wire and having more harmful
        consequences. <br />
      </span>,
      <span key={3}>Have another look and try again.</span>,
    ],
  },
  {
    Success: [
      <span key={1}>
        The answer is correct!
        <br />
      </span>,
      <span key={2} style={{ color: "rgb(80, 162, 32)" }}>
        Providing an accurate code was decisive to have a successful outcome. In
        Quality, the data must be accurate to be reliable and have the desired
        outcome. Quality audits confirm that the process and data are reliable.{" "}
        <br />
      </span>,
      <span key={3}>Great work team!</span>,
    ],
    Failure: [
      <span key={1}>
        The answer is incorrect, and your team received a 30 second penalty.
        <br />
      </span>,
      <span key={2} style={{ color: "rgb(244, 29, 37)" }}>
        If the data is unreliable, we cannot guarantee product safety and have
        an unsuccessful result. <br />
      </span>,
      <span key={3}>Have another look and try again.</span>,
    ],
  },
  {
    Success: [
      <span key={1}>
        The answer is correct!
        <br />
      </span>,
      <span key={2} style={{ color: "rgb(80, 162, 32)" }}>
        Approving and reviewing a Change Control requires checking all evidence
        and content in QualityOne. Thus, it is possible to understand the whole
        CC. <br />
      </span>,
      <span key={3}>Great work team!</span>,
    ],
    Failure: [
      <span key={1}>
        The answer is incorrect, and your team received a 30 second penalty.
        <br />
      </span>,
      <span key={2} style={{ color: "rgb(244, 29, 37)" }}>
        It is not acceptable to discard quality-related records for any reason
        unless the retention period expiry is reached. The product/activity
        history is based on evidence. <br />
      </span>,
      <span key={3}>Have another look and try again.</span>,
    ],
  },
  {
    Success: [
      <span key={1}>
        The answer is correct!
        <br />
      </span>,
      <span key={2} style={{ color: "rgb(80, 162, 32)" }}>
        In the case of a recall, product traceability is the key step to
        conducting an effective recall. As in this challenge, we know the
        location of the affected product based on the activities recorded.{" "}
        <br />
      </span>,
      <span key={3}>Great work team!</span>,
    ],
    Failure: [
      <span key={1}>
        The answer is incorrect, and your team received a 30 second penalty.
        <br />
      </span>,
      <span key={2} style={{ color: "rgb(244, 29, 37)" }}>
        A recall is a critical process that can affect the company's reputation
        and consumer safety. We need full details of all steps from the
        manufacturer to the consumer to investigate the issue, like in this
        challenge. <br />
      </span>,
      <span key={3}>Have another look and try again.</span>,
    ],
  },
  {
    Success: [
      <span key={1}>
        The answer is correct!
        <br />
      </span>,
      <span key={2} style={{ color: "rgb(80, 162, 32)" }}>
        Decisions should be made based on risk evaluation considering knowledge
        and link to the protection of the consumer.
        <br />
      </span>,
      <span key={3}>Great work team!</span>,
    ],
    Failure: [
      <span key={1}>
        The answer is incorrect, and your team received a 30 second penalty.
        <br />
      </span>,
      <span key={2} style={{ color: "rgb(244, 29, 37)" }}>
        Although some situations require a quick decision, the level of effort,
        formality and documentation of the quality risk management process
        should be commensurate with the level of risk. <br />
      </span>,
      <span key={3}>Have another look and try again.</span>,
    ],
  },
  {
    Success: [
      <span key={1}>
        The answer is correct!
        <br />
      </span>,
      <span key={2} style={{ color: "rgb(80, 162, 32)" }}>
        Well done! During a Quality audit, the auditor also must have a holistic
        approach to assess all the steps and evidence. <br />
      </span>,
      <span key={3}>Great work team!</span>,
    ],
    Failure: [
      <span key={1}>
        The answer is incorrect, and your team received a 30 second penalty.
        <br />
      </span>,
      <span key={2} style={{ color: "rgb(244, 29, 37)" }}>
        To have an effective culture of quality, we need to see quality all
        around and have a comprehensive approach. All processes are linked!{" "}
        <br />
      </span>,
      <span key={3}>Have another look and try again.</span>,
    ],
  },
];
