import React, { Fragment, useContext, useEffect, useState } from "react";
import classes from "./step9Mapper.module.scss"
import { getTranslatedWord } from "../../../metadata/ButtonText";
import { GamestateContext } from "../../../context/GameState/GameStateContext";

import img1fixed from "../../../assets/BlackeyeTreasure/Step9/1fixed.jpg";
import img2fixed from "../../../assets/BlackeyeTreasure/Step9/2fixed.jpg";
import img3layer1 from "../../../assets/BlackeyeTreasure/Step9/3layer1.jpg";
import img3layer2 from "../../../assets/BlackeyeTreasure/Step9/3layer2.jpg";
import img4layer1 from "../../../assets/BlackeyeTreasure/Step9/4layer1.jpg";
import img4layer2 from "../../../assets/BlackeyeTreasure/Step9/4layer2.jpg";
import img5layer1 from "../../../assets/BlackeyeTreasure/Step9/5layer1.jpg";
import img5layer2 from "../../../assets/BlackeyeTreasure/Step9/5layer2.jpg";
import img6layer1 from "../../../assets/BlackeyeTreasure/Step9/6layer1.jpg";
import img6layer2 from "../../../assets/BlackeyeTreasure/Step9/6layer2.jpg";
import img7fixed from "../../../assets/BlackeyeTreasure/Step9/7fixed.jpg";
import img8fixed from "../../../assets/BlackeyeTreasure/Step9/8fixed.jpg";
import img9layer1 from "../../../assets/BlackeyeTreasure/Step9/9layer1.jpg";
import img9layer2 from "../../../assets/BlackeyeTreasure/Step9/9layer2.jpg";
import img10layer1 from "../../../assets/BlackeyeTreasure/Step9/10layer1.jpg";
import img10layer2 from "../../../assets/BlackeyeTreasure/Step9/10layer2.jpg";
import img11fixed from "../../../assets/BlackeyeTreasure/Step9/11fixed.jpg";
import img12fixed from "../../../assets/BlackeyeTreasure/Step9/12fixed.jpg";
import img13layer1 from "../../../assets/BlackeyeTreasure/Step9/13layer1.jpg";
import img13layer2 from "../../../assets/BlackeyeTreasure/Step9/13layer2.jpg";
import img14layer1 from "../../../assets/BlackeyeTreasure/Step9/14layer1.jpg";
import img14layer2 from "../../../assets/BlackeyeTreasure/Step9/14layer2.jpg";
import img15layer1 from "../../../assets/BlackeyeTreasure/Step9/15layer1.jpg";
import img15layer2 from "../../../assets/BlackeyeTreasure/Step9/15layer2.jpg";
import img16layer1 from "../../../assets/BlackeyeTreasure/Step9/16layer1.jpg";
import img16layer2 from "../../../assets/BlackeyeTreasure/Step9/16layer2.jpg";
import img17layer1 from "../../../assets/BlackeyeTreasure/Step9/17layer1.jpg";
import img17layer2 from "../../../assets/BlackeyeTreasure/Step9/17layer2.jpg";
import img18fixed from "../../../assets/BlackeyeTreasure/Step9/18fixed.jpg";
import img19layer1 from "../../../assets/BlackeyeTreasure/Step9/19layer1.jpg";
import img19layer2 from "../../../assets/BlackeyeTreasure/Step9/19layer2.jpg";
import img20layer1 from "../../../assets/BlackeyeTreasure/Step9/20layer1.jpg";
import img20layer2 from "../../../assets/BlackeyeTreasure/Step9/20layer2.jpg";
import img21fixed from "../../../assets/BlackeyeTreasure/Step9/21fixed.jpg";
import img22layer1 from "../../../assets/BlackeyeTreasure/Step9/22layer1.jpg";
import img22layer2 from "../../../assets/BlackeyeTreasure/Step9/22layer2.jpg";
import img23layer1 from "../../../assets/BlackeyeTreasure/Step9/23layer1.jpg";
import img23layer2 from "../../../assets/BlackeyeTreasure/Step9/23layer2.jpg";
import img24layer1 from "../../../assets/BlackeyeTreasure/Step9/24layer1.jpg";
import img24layer2 from "../../../assets/BlackeyeTreasure/Step9/24layer2.jpg";
import img25layer1 from "../../../assets/BlackeyeTreasure/Step9/25layer1.jpg";
import img25layer2 from "../../../assets/BlackeyeTreasure/Step9/25layer2.jpg";
import img26fixed from "../../../assets/BlackeyeTreasure/Step9/26fixed.jpg";
import img27fixed from "../../../assets/BlackeyeTreasure/Step9/27fixed.jpg";
import img28layer1 from "../../../assets/BlackeyeTreasure/Step9/28layer1.jpg";
import img28layer2 from "../../../assets/BlackeyeTreasure/Step9/28layer2.jpg";
import img29layer1 from "../../../assets/BlackeyeTreasure/Step9/29layer1.jpg";
import img29layer2 from "../../../assets/BlackeyeTreasure/Step9/29layer2.jpg";
import img30layer1 from "../../../assets/BlackeyeTreasure/Step9/30layer1.jpg";
import img30layer2 from "../../../assets/BlackeyeTreasure/Step9/30layer2.jpg";
import img31layer1 from "../../../assets/BlackeyeTreasure/Step9/31layer1.jpg";
import img31layer2 from "../../../assets/BlackeyeTreasure/Step9/31layer2.jpg";
import img32fixed from "../../../assets/BlackeyeTreasure/Step9/32fixed.jpg";
import img33layer1 from "../../../assets/BlackeyeTreasure/Step9/33layer1.jpg";
import img33layer2 from "../../../assets/BlackeyeTreasure/Step9/33layer2.jpg";
import img34layer1 from "../../../assets/BlackeyeTreasure/Step9/34layer1.jpg";
import img34layer2 from "../../../assets/BlackeyeTreasure/Step9/34layer2.jpg";
import img35layer1 from "../../../assets/BlackeyeTreasure/Step9/35layer1.jpg";
import img35layer2 from "../../../assets/BlackeyeTreasure/Step9/35layer2.jpg";
import img36layer1 from "../../../assets/BlackeyeTreasure/Step9/36layer1.jpg";
import img36layer2 from "../../../assets/BlackeyeTreasure/Step9/36layer2.jpg";
import img37layer1 from "../../../assets/BlackeyeTreasure/Step9/37layer1.jpg";
import img37layer2 from "../../../assets/BlackeyeTreasure/Step9/37layer2.jpg";
import img38layer1 from "../../../assets/BlackeyeTreasure/Step9/38layer1.jpg";
import img38layer2 from "../../../assets/BlackeyeTreasure/Step9/38layer2.jpg";
import img39layer1 from "../../../assets/BlackeyeTreasure/Step9/39layer1.jpg";
import img39layer2 from "../../../assets/BlackeyeTreasure/Step9/39layer2.jpg";
import img40layer1 from "../../../assets/BlackeyeTreasure/Step9/40layer1.jpg";
import img40layer2 from "../../../assets/BlackeyeTreasure/Step9/40layer2.jpg";
import img41layer1 from "../../../assets/BlackeyeTreasure/Step9/41layer1.jpg";
import img41layer2 from "../../../assets/BlackeyeTreasure/Step9/41layer2.jpg";
import img42layer1 from "../../../assets/BlackeyeTreasure/Step9/42layer1.jpg";
import img42layer2 from "../../../assets/BlackeyeTreasure/Step9/42layer2.jpg";
import img43fixed from "../../../assets/BlackeyeTreasure/Step9/43fixed.jpg";
import img44fixed from "../../../assets/BlackeyeTreasure/Step9/44fixed.jpg";
import img45layer1 from "../../../assets/BlackeyeTreasure/Step9/45layer1.jpg";
import img45layer2 from "../../../assets/BlackeyeTreasure/Step9/45layer2.jpg";
import img46layer1 from "../../../assets/BlackeyeTreasure/Step9/46layer1.jpg";
import img46layer2 from "../../../assets/BlackeyeTreasure/Step9/46layer2.jpg";
import img47layer1 from "../../../assets/BlackeyeTreasure/Step9/47layer1.jpg";
import img47layer2 from "../../../assets/BlackeyeTreasure/Step9/47layer2.jpg";
import img48layer1 from "../../../assets/BlackeyeTreasure/Step9/48layer1.jpg";
import img48layer2 from "../../../assets/BlackeyeTreasure/Step9/48layer2.jpg";
import img49layer1 from "../../../assets/BlackeyeTreasure/Step9/49layer1.jpg";
import img49layer2 from "../../../assets/BlackeyeTreasure/Step9/49layer2.jpg";
import img50layer1 from "../../../assets/BlackeyeTreasure/Step9/50layer1.jpg";
import img50layer2 from "../../../assets/BlackeyeTreasure/Step9/50layer2.jpg";
import img51layer1 from "../../../assets/BlackeyeTreasure/Step9/51layer1.jpg";
import img51layer2 from "../../../assets/BlackeyeTreasure/Step9/51layer2.jpg";
import img52layer1 from "../../../assets/BlackeyeTreasure/Step9/52layer1.jpg";
import img52layer2 from "../../../assets/BlackeyeTreasure/Step9/52layer2.jpg";
import img53layer1 from "../../../assets/BlackeyeTreasure/Step9/53layer1.jpg";
import img53layer2 from "../../../assets/BlackeyeTreasure/Step9/53layer2.jpg";
import img54layer1 from "../../../assets/BlackeyeTreasure/Step9/54layer1.jpg";
import img54layer2 from "../../../assets/BlackeyeTreasure/Step9/54layer2.jpg";
import img55layer1 from "../../../assets/BlackeyeTreasure/Step9/55layer1.jpg";
import img55layer2 from "../../../assets/BlackeyeTreasure/Step9/55layer2.jpg";
import img56layer1 from "../../../assets/BlackeyeTreasure/Step9/56layer1.jpg";
import img56layer2 from "../../../assets/BlackeyeTreasure/Step9/56layer2.jpg";
import img57fixed from "../../../assets/BlackeyeTreasure/Step9/57fixed.jpg";
import img58layer1 from "../../../assets/BlackeyeTreasure/Step9/58layer1.jpg";
import img58layer2 from "../../../assets/BlackeyeTreasure/Step9/58layer2.jpg";
import img59layer1 from "../../../assets/BlackeyeTreasure/Step9/59layer1.jpg";
import img59layer2 from "../../../assets/BlackeyeTreasure/Step9/59layer2.jpg";
import img60layer1 from "../../../assets/BlackeyeTreasure/Step9/60layer1.jpg";
import img60layer2 from "../../../assets/BlackeyeTreasure/Step9/60layer2.jpg";
import img61layer1 from "../../../assets/BlackeyeTreasure/Step9/61layer1.jpg";
import img61layer2 from "../../../assets/BlackeyeTreasure/Step9/61layer2.jpg";
import img62layer1 from "../../../assets/BlackeyeTreasure/Step9/62layer1.jpg";
import img62layer2 from "../../../assets/BlackeyeTreasure/Step9/62layer2.jpg";
import img63layer1 from "../../../assets/BlackeyeTreasure/Step9/63layer1.jpg";
import img63layer2 from "../../../assets/BlackeyeTreasure/Step9/63layer2.jpg";
import img64fixed from "../../../assets/BlackeyeTreasure/Step9/64fixed.jpg";
import { BTGameContext } from "../../../context/BTGameContext/BTGameContext";

export interface ImageObject {
  src: string;
  type: string;
  src2?: string;
  layer1Image?: string,
}

const imageList: ImageObject[] = [
  { src: img1fixed, type: "fixed" },
  { src: img2fixed, type: "fixed" },
  { src: img3layer1, type: "layer1", src2: img3layer2, layer1Image: img3layer1 },
  { src: img4layer1, type: "layer1", src2: img4layer2, layer1Image: img4layer1 },
  { src: img5layer1, type: "layer1", src2: img5layer2, layer1Image: img5layer1 },
  { src: img6layer1, type: "layer1", src2: img6layer2, layer1Image: img6layer1 },
  { src: img7fixed, type: "fixed" },
  { src: img8fixed, type: "fixed" },
  { src: img9layer1, type: "layer1", src2: img9layer2, layer1Image: img9layer1 },
  { src: img10layer1, type: "layer1", src2: img10layer2, layer1Image: img10layer1 },
  { src: img11fixed, type: "fixed" },
  { src: img12fixed, type: "fixed" },
  { src: img13layer1, type: "layer1", src2: img13layer2, layer1Image: img13layer1 },
  { src: img14layer1, type: "layer1", src2: img14layer2, layer1Image: img14layer1 },
  { src: img15layer1, type: "layer1", src2: img15layer2, layer1Image: img15layer1 },
  { src: img16layer1, type: "layer1", src2: img16layer2, layer1Image: img16layer1 },
  { src: img17layer1, type: "layer1", src2: img17layer2, layer1Image: img17layer1 },
  { src: img18fixed, type: "fixed" },
  { src: img19layer1, type: "layer1", src2: img19layer2, layer1Image: img19layer1 },
  { src: img20layer1, type: "layer1", src2: img20layer2, layer1Image: img20layer1 },
  { src: img21fixed, type: "fixed" },
  { src: img22layer1, type: "layer1", src2: img22layer2, layer1Image: img22layer1 },
  { src: img23layer1, type: "layer1", src2: img23layer2, layer1Image: img23layer1 },
  { src: img24layer1, type: "layer1", src2: img24layer2, layer1Image: img24layer1 },
  { src: img25layer1, type: "layer1", src2: img25layer2, layer1Image: img25layer1 },
  { src: img26fixed, type: "fixed" },
  { src: img27fixed, type: "fixed" },
  { src: img28layer1, type: "layer1", src2: img28layer2, layer1Image: img28layer1 },
  { src: img29layer1, type: "layer1", src2: img29layer2, layer1Image: img29layer1 },
  { src: img30layer1, type: "layer1", src2: img30layer2, layer1Image: img30layer1 },
  { src: img31layer1, type: "layer1", src2: img31layer2, layer1Image: img31layer1 },
  { src: img32fixed, type: "fixed" },
  { src: img33layer1, type: "layer1", src2: img33layer2, layer1Image: img33layer1 },
  { src: img34layer1, type: "layer1", src2: img34layer2, layer1Image: img34layer1 },
  { src: img35layer1, type: "layer1", src2: img35layer2, layer1Image: img35layer1 },
  { src: img36layer1, type: "layer1", src2: img36layer2, layer1Image: img36layer1 },
  { src: img37layer1, type: "layer1", src2: img37layer2, layer1Image: img37layer1 },
  { src: img38layer1, type: "layer1", src2: img38layer2, layer1Image: img38layer1 },
  { src: img39layer1, type: "layer1", src2: img39layer2, layer1Image: img39layer1 },
  { src: img40layer1, type: "layer1", src2: img40layer2, layer1Image: img40layer1 },
  { src: img41layer1, type: "layer1", src2: img41layer2, layer1Image: img41layer1 },
  { src: img42layer1, type: "layer1", src2: img42layer2, layer1Image: img42layer1 },
  { src: img43fixed, type: "fixed" },
  { src: img44fixed, type: "fixed" },
  { src: img45layer1, type: "layer1", src2: img45layer2, layer1Image: img45layer1 },
  { src: img46layer1, type: "layer1", src2: img46layer2, layer1Image: img46layer1 },
  { src: img47layer1, type: "layer1", src2: img47layer2, layer1Image: img47layer1 },
  { src: img48layer1, type: "layer1", src2: img48layer2, layer1Image: img48layer1 },
  { src: img49layer1, type: "layer1", src2: img49layer2, layer1Image: img49layer1 },
  { src: img50layer1, type: "layer1", src2: img50layer2, layer1Image: img50layer1 },
  { src: img51layer1, type: "layer1", src2: img51layer2, layer1Image: img51layer1 },
  { src: img52layer1, type: "layer1", src2: img52layer2, layer1Image: img52layer1 },
  { src: img53layer1, type: "layer1", src2: img53layer2, layer1Image: img53layer1 },
  { src: img54layer1, type: "layer1", src2: img54layer2, layer1Image: img54layer1 },
  { src: img55layer1, type: "layer1", src2: img55layer2, layer1Image: img55layer1 },
  { src: img56layer1, type: "layer1", src2: img56layer2, layer1Image: img56layer1 },
  { src: img57fixed, type: "fixed" },
  { src: img58layer1, type: "layer1", src2: img58layer2, layer1Image: img58layer1 },
  { src: img59layer1, type: "layer1", src2: img59layer2, layer1Image: img59layer1 },
  { src: img60layer1, type: "layer1", src2: img60layer2, layer1Image: img60layer1 },
  { src: img61layer1, type: "layer1", src2: img61layer2, layer1Image: img61layer1 },
  { src: img62layer1, type: "layer1", src2: img62layer2, layer1Image: img62layer1 },
  { src: img63layer1, type: "layer1", src2: img63layer2, layer1Image: img63layer1 },
  { src: img64fixed, type: "fixed" },
];
export interface IImageDiggingPanel extends React.ImgHTMLAttributes<HTMLImageElement> {
  footerText?: string;
}

const Step9Mapper: React.FC<IImageDiggingPanel> = ({
  footerText,
  ...rest
}) => {
  const [images, setImages] = useState(imageList)
  const { gameLanguage } = useContext(GamestateContext);
  const { updateTreasureFoundImage } = useContext(BTGameContext)
  const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement | null>(null);
  const [lastDiggedImage, setLastDiggedImage] = useState<number | undefined>();

  useEffect(() => {
    return (() => {
      updateTreasureFoundImage([]);
    })
  }, [])


  const handleClick = (image: any, index: number) => {
    if (index === 41 && image.type === "layer2") {
      updateTreasureFoundImage([`${index + 1}`]);
    }
    if (image.type === "fixed" || image.type === "layer2") return;

    if (image.type === "layer1") {
      if (currentAudio) {
        currentAudio.pause();
        currentAudio.currentTime = 0;
      }

      const audio = new Audio("assests/GameFiles/BT/dig.mp3");
      setCurrentAudio(audio);
      audio.play();

      setTimeout(() => {
        const newImages = [...images];
        if (lastDiggedImage) {
          newImages[lastDiggedImage] = { ...newImages[lastDiggedImage], src: newImages[lastDiggedImage].layer1Image!, type: "layer1" }
        }
        newImages[index] = { ...newImages[index], src: newImages[index].src2!, type: "layer2" };
        setImages(newImages);
        setLastDiggedImage(index);

      }, 1000);
    }
  };

  const renderImages = () => {
    return images.map((image, index) => (
      <div
        key={index}
        className={classes["image-container"]}
        onClick={() => handleClick(image, index)}
      >
        <div
          className={classes["layer1-image"]}
          style={{ backgroundImage: `url(${image.src})` }}
        />
        <div
          className={`${classes["layer2-image"]} }`}
          style={{ backgroundImage: `url(${image.src2})` }}
        />
      </div>
    ));
  };
  return (
    <Fragment>
      <div className={classes["content-container"]} style={rest.style}>
        <div className={classes["images-container"]}>
          {renderImages()}
        </div>
        <div className={classes["content-container-text"]}>
          <span>
            {footerText === undefined
              ? getTranslatedWord("(click an area to dig)", gameLanguage)
              : footerText}
          </span>
        </div>
      </div>
    </Fragment>
  );
};

export default Step9Mapper;