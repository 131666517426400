import Levenshtein from "levenshtein";

export const calculateMatchingPercentage = (
  lyrics1: string,
  lyrics2: string
) => {
  const distance: any = new Levenshtein(
    lyrics1.toLowerCase(),
    lyrics2.toLowerCase()
  );
  const max_length = Math.max(lyrics1.length, lyrics2.length);
  const matching_percentage = ((max_length - distance) / max_length) * 100;
  return matching_percentage.toFixed(2);
};

export const styleTime = (secs: number) => {
  if (secs <= 0) {
    return "00:00";
  }
  var minutes = Math.floor(secs / 60);
  var seconds = Math.floor(secs - minutes * 60);
  let timeFormatting = "";
  if (minutes < 10) {
    timeFormatting = "0" + minutes + ":";
  } else {
    timeFormatting = minutes + ":";
  }
  if (seconds < 10) {
    timeFormatting = timeFormatting + "0" + seconds;
  } else {
    timeFormatting = timeFormatting + "" + seconds;
  }
  return timeFormatting;
};

export const SecToMin = (secs: number): number => {
  return Math.trunc(secs / 60);
};
export const Clamp = (value: number, min: number, max: number): number => {
  if (value >= min && value <= max) return value;
  if (value < min) return min;
  return max;
};

export const StringToNumber = (value: string): number => {
  let filterElements = value.match(/\d+/);
  filterElements =
    filterElements === undefined || filterElements === null
      ? []
      : filterElements;
  return filterElements.length > 0 ? parseInt(filterElements[0]) : 0;
};

export const CacheImages = async (images: string[]) => {
  const promises = await images.map((src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = resolve;
      img.onerror = resolve;
    });
  });
  await Promise.all(promises);
};

export const IsImage = (path: string) => {
  let extension = path.split(".").pop() || "";
  const extensions = ["jpg", "jpeg", "png", "gif", "tiff"];
  return extensions.includes(extension);
};

export const delay = (time: number) => {
  return new Promise((resolve, rej) => {
    setTimeout(() => {
      resolve(0);
    }, time);
  });
};
