import React from "react";
import classes from "./messagebox.module.scss";
import CFontAwesomeIcon from "../customfonticon/cfontawesomicon";
import Backdrop from "../backdrop/backdrop";
import { EMessageType } from "../../container/Enums/Enums";
import { INFO_MARK_ICON, TICK_MARK_ICON } from "../../icons";

interface IMessageBox {
  type: EMessageType;
  title: string;
  message: React.ReactNode[];
  button1?: React.ReactNode;
  button2?: React.ReactNode;
  titleIcon?: string;
  boxContainerClassName?: string;
  boxTitleClassName?: string;
  boxInnerContainerClassName?: string;
  boxMessageContainerClassName?: string;
  boxButtonContainerClassName?: string;
}

const getIcon = (type: EMessageType): string => {
  switch (type) {
    case EMessageType.Success:
      return TICK_MARK_ICON;
    case EMessageType.Error:
      return "fas fa-times";
    case EMessageType.Info:
      return INFO_MARK_ICON;
    default:
      return "fas fa-times";
  }
};
const getIconColor = (type: EMessageType): string => {
  switch (type) {
    case EMessageType.Success:
      return "#50A220";
    case EMessageType.Error:
      return "#F41D25";
    case EMessageType.Info:
      return "#35515D";
    default:
      return "#F41D25";
  }
};
const MessageBox: React.FC<IMessageBox> = ({
  type,
  title,
  message,
  button1,
  button2,
  titleIcon,
  boxContainerClassName,
  boxTitleClassName,
  boxInnerContainerClassName,
  boxMessageContainerClassName,
  boxButtonContainerClassName,
}) => {
  const icon = titleIcon ? titleIcon : getIcon(type);
  const iconColor = getIconColor(type);

  return (
    <Backdrop>
      <div className={`${classes.container} ${boxContainerClassName || ""}`}>
        <span
          style={{
            fontSize: "1.2em",
            color: `${iconColor}`,
            display: "flex",
            alignItems: "center",
          }}
          className={`${boxTitleClassName ||""}`}
        >
          <CFontAwesomeIcon
            parentIcon="fas fa-circle"
            childIcon={icon}
            style={{ fontSize: "1.8em" }}
          ></CFontAwesomeIcon>
          &nbsp;&nbsp;{title}
        </span>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "15px",
            flexGrow: "1",
          }}
          className={boxInnerContainerClassName||""}
        >
          <div
            style={{
              height: "65%",
              display: "flex",
              flexDirection: "column",
              marginTop: "20px",
              fontSize: "1.4em",
              width: "90%",
              marginLeft: "20px",
            }}
            className={boxMessageContainerClassName||""}
          >
            {message.map((x, index) => {
              return x;
            })}
          </div>
          <div
            className={`${classes["button-container"]} ${
              boxButtonContainerClassName || ""
            }`}
          >
            {button2}
            {button1}
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default MessageBox;
