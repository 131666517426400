// LinkMap.tsx
import React, { useState, useCallback } from "react";
import ZoomImagePanel from "../imagepanel/zoomable-image";
import { IArea } from "./imageMapper";
import { IsImage } from "../../../utilities/util";

export interface ILinkMap {
  linkText: string;
  image: string;
}

interface ILinkMapper extends ILinkMap {
  dimensions: number[];
  areas: IArea[];
  onAreaClick: (index: number) => void;
}

const LinkMapper: React.FC<ILinkMapper> = ({
  linkText,
  dimensions,
  areas,
  image,
  onAreaClick,
}) => {
  const [openImage, setOpenImage] = useState<string | null>(null);

  const onLinkClick = useCallback(() => {
    if (IsImage(image)) {
      setOpenImage(image);
    } else {
      window.open(image, "_blank");
    }
  }, [image]);

  const onCloseImage = useCallback(() => {
    setOpenImage(null);
  }, []);

  return (
    <div>
      <span
        style={{ cursor: "pointer", textDecoration: "underline", color: "red" }}
        onClick={onLinkClick}
      >
        {linkText}
      </span>
      {openImage && (
        <ZoomImagePanel
          image={openImage}
          defaultScale={0.6}
          onClosePress={onCloseImage}
          dimensions={dimensions}
          areas={areas}
          onAreaClick={onAreaClick}
        />
      )}
    </div>
  );
};

export default LinkMapper;
