import React, { Fragment } from "react";
import ImageMapper, { IImagePanel } from "../../game/imageMapper/imageMapper";

const defaultButtonState = [
  { coords: [1805, 589, 1985, 784], link: true, pointerCursor: true },
  { coords: [1154, 199, 1323, 443], link: true, pointerCursor: true },
  { coords: [1603, 231, 1787, 444], link: true, pointerCursor: true },
  { coords: [1080, 589, 1235, 800], link: true, pointerCursor: true },
  { coords: [540, 667, 740, 856], link: true, pointerCursor: true },
  { coords: [1654, 772, 1806, 919], link: true, pointerCursor: true },
  { coords: [742, 526, 921, 693], link: true, pointerCursor: true },
  { coords: [816, 850, 1020, 1006], link: true, pointerCursor: true },
  { coords: [1254, 756, 1421, 949], link: true, pointerCursor: true },
  { coords: [1447, 621, 1610, 825], link: true, pointerCursor: true },
];

const Step8Mapper: React.FC<IImagePanel> = ({
  linkImage,
  viewerImage,
  footerText,
  defaultScale,
  style,
}) => {
  const onAreaClicked = (index: number) => {
    const a = new Audio(`assests/GameFiles/BT/${index + 1}.mp3`);
    a.play();
  };

  return (
    <Fragment>
      <ImageMapper
        linkImage={linkImage}
        viewerImage={viewerImage}
        footerText={footerText}
        style={style}
        defaultScale={defaultScale}
        dimensions={[2793, 1600]}
        areas={[...defaultButtonState]}
        onAreaClick={onAreaClicked}
      ></ImageMapper>
    </Fragment>
  );
};

export default Step8Mapper;
