import React, { useEffect, useState } from "react";
import classes from "./dropdowncontainer.module.scss";
import DropDown from "../../dropdown/dropdown";

interface IDropDownsContainer {
  options: string[][] | undefined;
  onChange: (updatedValues: string) => void;
}
const DropDownsContainer: React.FC<IDropDownsContainer> = ({
  options,
  onChange,
}) => {
  const [containerSelectValues, setContainerSelectValues] = useState<string[]>(
    []
  );

  useEffect(() => {
    if (options) {
      const selectValues = options.map((selectOptions) => selectOptions[1]);
      setContainerSelectValues(selectValues);
    }
  }, [options]);

  const onChangeHandler = (
    event: React.ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const updatedValues = [...containerSelectValues];
    updatedValues[index] = event.target.value;
    setContainerSelectValues(updatedValues);
    const filterUpdatedValues = updatedValues.filter((value) => value !== "");
    if (filterUpdatedValues.length === options?.length) {
      const joinSelectsValues = updatedValues.join("");
      onChange(joinSelectsValues);
    } else {
      onChange("");
    }
  };

  // Split the options into two halves
  const halfLength = Math.ceil((options?.length ?? 0) / 2);
  const firstHalf = options?.slice(0, halfLength);
  const secondHalf = options?.slice(halfLength);

  const firstDiv = firstHalf?.map((selectOptions, index) => {
    const [selectLabel, ...restOptions] = selectOptions;
    return (
      <div className={classes["wrapper"]} key={index}>
        <label
          style={{ marginRight: "35px", marginBottom: 0, minWidth: "85px" }}
        >
          {selectLabel}
        </label>
        <DropDown
          key={index}
          options={restOptions}
          onChange={(e) => onChangeHandler(e, index)}
          style={{ width: "55%" }}
        />
      </div>
    );
  });

  const secondDiv = secondHalf?.map((selectOptions, index) => {
    const [selectLabel, ...restOptions] = selectOptions;
    return (
      <div className={classes["wrapper"]} key={index}>
        <label
          style={{ marginRight: "35px", marginBottom: 0, minWidth: "85px" }}
        >
          {selectLabel}
        </label>
        <DropDown
          key={index + halfLength}
          options={restOptions}
          onChange={(e) => onChangeHandler(e, index + halfLength)} // Adjust index
          style={{ width: "55%" }}
        />
      </div>
    );
  });

  return (
    <div className={classes["dropdowns-container"]}>
      <div
        style={{ flex: "0 1 45%", display: "flex", flexDirection: "column" }}
      >
        {firstDiv}
      </div>
      <div
        style={{ flex: "0 1 45%", display: "flex", flexDirection: "column" }}
      >
        {secondDiv}
      </div>
    </div>
  );
};

export default DropDownsContainer;
