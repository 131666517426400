import { Route, Switch } from "react-router";
import React, { Suspense } from "react";
import { CircularSpinner } from "../../components/Spinner/Spinner";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import {
  ROUTE_MENU,
  ROUTE_GAME_RULES,
  ROUTE_RESUME_INTRO,
  ROUTE_LOGIN,
  ROUTE_GAME_STEP,
  ROUTE_RESULT,
  ROUTE_HOW_TO_PLAY,
  ROUTE_LEADERBOARD,
  ROUTE_TEAM_NAME,
  ROUTE_START_INTRO,
  ROUTE_LOGIN_NL,
  ROUTE_CODE_RESULT_VIEWER,
} from "../../constants";
import { EGameScreen } from "../Enums/Enums";

const LoginContainer = React.lazy(() => import("../Login/Login"));
const LoginNLContainer = React.lazy(() => import("../Login/LoginDutch"));
const GameMenuContainer = React.lazy(() => import("../GameMenu/Menu"));
const GameRuleContainer = React.lazy(() => import("../GameIntro/GameRules"));
const GameStartContainer = React.lazy(() => import("../GameIntro/GameIntro"));
const GameResumeIntroContainer = React.lazy(
  () => import("../ResumeIntro/ResumeIntro")
);
const GameStepContainer = React.lazy(() => import("../GameScreen/GameScreen"));
const ResultContainer = React.lazy(() => import("../GameScreen/ResultScreen"));
const Leaderboard = React.lazy(() => import("../Leaderboard/Leaderboard"));
const CodeResultViewer = React.lazy(
  () => import("../Leaderboard/CodeResultViewer")
);
const HowToPlay = React.lazy(() => import("../HowToPlay/HowToPlay"));
const TeamNameContainer = React.lazy(() => import("../Team/TeamName"));

const Routes: React.FunctionComponent = () => {
  return (
    <Suspense
      fallback={<CircularSpinner backdropAlpha={false}></CircularSpinner>}
    >
      <Switch>
        <PrivateRoute
          currentScreen={EGameScreen.Question}
          path={ROUTE_GAME_STEP}
          exact={true}
          component={GameStepContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={ROUTE_RESULT}
          exact={true}
          component={ResultContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={ROUTE_MENU}
          exact={true}
          component={GameMenuContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={ROUTE_LEADERBOARD}
          exact={true}
          component={Leaderboard}
        ></PrivateRoute>
        <PrivateRoute
          path={ROUTE_TEAM_NAME}
          exact={true}
          component={TeamNameContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={ROUTE_HOW_TO_PLAY}
          exact={true}
          component={HowToPlay}
        ></PrivateRoute>
        <PrivateRoute
          path={ROUTE_GAME_RULES}
          exact={true}
          component={GameRuleContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={ROUTE_START_INTRO}
          exact={true}
          component={GameStartContainer}
        ></PrivateRoute>
        <PrivateRoute
          path={ROUTE_RESUME_INTRO}
          exact={true}
          component={GameResumeIntroContainer}
        ></PrivateRoute>
        <Route
          path={ROUTE_CODE_RESULT_VIEWER}
          exact={true}
          component={CodeResultViewer}
        ></Route>
        <Route
          path={ROUTE_LOGIN_NL}
          exact={true}
          component={LoginNLContainer}
        ></Route>
        <Route
          path={ROUTE_LOGIN}
          exact={true}
          component={LoginContainer}
        ></Route>
      </Switch>
    </Suspense>
  );
};

export default Routes;
