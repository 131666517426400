import { GAME_TIME } from "../../constants";
import {
  GameLanguages,
  EGameScreen,
  GameTypes,
  EGameCompany,
} from "../../container/Enums/Enums";

export interface IGameState {
  gameStartTime: number;
  gameTime: number;
  gameStepNo: string;
  resumeGameStep?: string;
  totalIncorrect: number;
  totalUseHints: number;
  useHints: string[];
  lastHintUsedTime: number;
  result: string;
  Step10Completed: boolean;
  step11Completed: boolean;
  gameContinued?: boolean;
  teamName?: string;
}

export const defaultGameState: IGameState = {
  gameStartTime: Number.MIN_SAFE_INTEGER,
  gameStepNo: "0",
  resumeGameStep: "",
  totalIncorrect: 0,
  totalUseHints: 0,
  lastHintUsedTime: 0,
  gameTime: GAME_TIME,
  useHints: [],
  result: "00",
  Step10Completed: true,
  step11Completed: true,
  gameContinued: false,
  teamName: "",
};

export interface GameStateInterface {
  gameCompany: string;
  gameType: string;
  gameLanguage: string;
  gameCode: string;
  screen: EGameScreen;
  gameState: IGameState;
  gameStepNo: string;
  totalIncorrect: number;
  lastHintUseTime: number;
  totalUseHints: number;
  useHints: string[];
  gameTime: number;
  gameStartTime: number;
  gameResult: string;
  currentAnswer: string;
  deductTime: (count: number) => void;
  setGameCode: (code: string) => void;
  setGameCompany: (type: string) => void;
  setGameType: (type: string) => void;
  setGameLanguage: (type: string) => void;
  setGameScreen: (screen: EGameScreen) => void;
  setCurrentStepAnswer: (answer: string) => void;
  updateGameState: (state: IGameState) => void;
  reset: () => void;
}

export const gameStateDefaultInterface: GameStateInterface = {
  gameCompany: EGameCompany.Experios,
  gameType: GameTypes.TT,
  gameLanguage: GameLanguages.ENUK,
  gameCode: "",
  screen: EGameScreen.Menu,
  gameState: defaultGameState,
  gameStepNo: "0",
  totalIncorrect: 0,
  lastHintUseTime: 0,
  totalUseHints: 0,
  useHints: [],
  gameTime: GAME_TIME,
  gameStartTime: Number.MAX_SAFE_INTEGER,
  gameResult: "00",
  currentAnswer: "",
  setGameCode: (code: string) => {},
  setGameCompany: (type: string) => {},
  setGameType: (type: string) => {},
  setGameScreen: () => {},
  setGameLanguage: (type: string) => {},
  setCurrentStepAnswer: (answer: string) => {},
  deductTime: (count: number) => {},
  updateGameState: (state: IGameState) => {},
  reset: () => {},
};
