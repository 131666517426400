import React, { Fragment } from "react";
import { EQuestionType, GameLanguages } from "../container/Enums/Enums";
import { IQuestion } from "./TTMeta";
import ImageViewer from "../components/game/imagepanel/imagepanel";
import Recording from "../components/game/recordings/recordingPanel";

import Step1 from "../assets/MayDay/Step1.jpg";
import Step2A from "../assets/MayDay/Step2a.jpg";
import Step2B from "../assets/MayDay/Step2b.jpg";
import Step3A from "../assets/MayDay/Step3a.jpg";
import Step3B from "../assets/MayDay/Step3b.jpg";
import Step4A from "../assets/MayDay/Step3a.jpg";
import Step4B from "../assets/MayDay/Step4b.jpg";
import Step5A from "../assets/MayDay/Step5a.jpg";
import Step6A from "../assets/MayDay/Step6a.jpg";
import Step6B from "../assets/MayDay/Step6b.jpg";
import Step7A from "../assets/MayDay/Step7a.jpg";
import Step7B from "../assets/MayDay/Step7a.jpg";
import Step8A from "../assets/MayDay/Step8a.jpg";
import Step9A from "../assets/MayDay/Step9a.jpg";
import Step9B_A from "../assets/MayDay/Step9b_a.jpg";
import Step9B_B from "../assets/MayDay/Step9b_b.jpg";
import Step9B_C from "../assets/MayDay/Step9b_c.jpg";
import Step9B_D from "../assets/MayDay/Step9b_d.jpg";
import Step9B_E from "../assets/MayDay/Step9b_e.jpg";
import Step10A from "../assets/MayDay/Step10a.jpg";
import Step10B from "../assets/MayDay/Step10b.jpg";
import Step12A from "../assets/MayDay/Step12a.jpg";
// import Step12B from "../assets/MayDay/Step12b.gif";
import Step12B_A from "../assets/MayDay/Step12b_a.jpg";
import Step12B_B from "../assets/MayDay/Step12b_b.jpg";
import Step12B_C from "../assets/MayDay/Step12b_c.jpg";
import Step12B_D from "../assets/MayDay/Step12b_d.jpg";
import Step12B_E from "../assets/MayDay/Step12b_e.jpg";
import Step12B_F from "../assets/MayDay/Step12b_f.jpg";
import Step12B_G from "../assets/MayDay/Step12b_g.jpg";
import Step12B_H from "../assets/MayDay/Step12b_h.jpg";

import Step2Map from "../components/mayday/Step2Map";
import Step7Map from "../components/mayday/Step7Map";
import SlideShow from "../components/game/imagepanel/ImageSlideShow";

export const getGameQuestionsByLanguage = (language?: string): IQuestion[] => {
  switch (language) {
    case GameLanguages.ENUK:
      return Questions;
    case GameLanguages.DUTCH:
      return Questions;
    default:
      return Questions;
  }
};

const Questions: IQuestion[] = [
  {
    Id: "1",
    Type: EQuestionType.Input,
    Question: "What is the 4 letter verification code?",
    Options: [""],
    Content: [Step1],
    Body: [
      <Fragment key={1}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>
          HIGH IN THE SKY
        </span>
        <span className={"mt-2"}>
          Yet another beautiful day to be a pilot high in the sky.<br></br>
          Sunshine, blue ocean and not a cloud to be seen.
        </span>
        <span className={"mt-3"}>
          If I manage to land on time, I might even be able to squeeze in some
          beach time before flying back.
        </span>

        <ImageViewer
          linkImage={Step1}
          viewerImage={Step1}
          footerText={"(Click to enlarge)"}
          style={{ maxWidth: "55%", minWidth: "40%" }}
          defaultScale={0.9}
        ></ImageViewer>
        <span
          className={"mt-2"}
          style={{ color: "#7ED321", fontSize: "0.8em" }}
        >
          SOS EMERGENCY
        </span>
        <span className={"mt-2"}>
          Mayday mayday mayday.<br></br>I am losing control of the plane!
        </span>
        <Recording
          header={"AUDIO FILE"}
          path={"assests/GameFiles/MayDay/Step1.mp3"}
          hasSlider={true}
        ></Recording>
      </Fragment>,
    ],
  },
  {
    Id: "2",
    Type: EQuestionType.Input,
    Question: "What is the 3 letter code of the nearest airport?",
    Options: [""],
    Body: [
      <Fragment key={2}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>WATER</span>
        <span className={"mt-2"}>
          Roger that!<br></br>
          Great to hear you are ready to provide me with some assistance.
        </span>
        <span className={"mt-3"}>
          All I can see around me is water, water and more water.<br></br>
          Oh and far away in the distance I can see a tiny dot. Looks like it's
          land!?
        </span>
        <ImageViewer
          linkImage={Step2A}
          viewerImage={Step2A}
          footerText={"(Click to enlarge)"}
          style={{ maxWidth: "55%", minWidth: "40%" }}
          defaultScale={0.9}
        ></ImageViewer>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>LANDING</span>
        <span className={"mt-2"}>
          I don't really care where we land, as long as there is a runway, or
          even a paddock.<br></br>
          This plane is about to go down any minute now. And the weather isn't
          improving either.
        </span>
        <ImageViewer
          linkImage={Step2B}
          viewerImage={Step2B}
          footerText={"(Click to enlarge)"}
          style={{ maxWidth: "75%", minWidth: "40%" }}
          defaultScale={0.9}
        ></ImageViewer>
        <span className={"mt-3"}>
          In all honesty, I've only recently got my pilot licence and have never
          been in a situation like this.
        </span>
        <span className={"mt-2"}>
          What I do know is that to land safely, we need to know more about the
          runway we're approaching.<br></br> Can you use my coordinates to tell
          me what the code of the airport is?<br></br> Then I'll see if I can
          find the plane's emergency manual.
        </span>
      </Fragment>,
    ],
    Content: [Step2A, Step2B],
  },
  {
    Id: "3",
    Type: EQuestionType.Input,
    Question: "What is the 3 digit transponder code Roger needs to set?",
    Options: [""],
    InputPattern: "^[0-9]*$",
    InputMaxLimit: 4,
    Body: [
      <Fragment key={3}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>
          EMERGENCY PROCEDURE
        </span>
        <span className={"mt-2"}>
          Setting course to the island, our only hope of survival.<br></br>
          And finally some good news, I've managed to find the plane's emergency
          manual.
        </span>
        <ImageViewer
          linkImage={`${process.env.PUBLIC_URL}/assests/GameFiles/MayDay/emergencymanualv7.pdf`}
          viewerImage={Step3A}
          footerText={"(Click to open)"}
          defaultScale={0.4}
          style={{ maxWidth: "35%", minWidth: "20%", padding: "20px" }}
        ></ImageViewer>

        <span className={"mt-3"}>
          Oh boy, I'm sweating like a silverback gorilla at the moment.<br></br>
          Can you take a look at the manual and tell me what to do? I think I
          need to set the transponder code!?<br></br>
          That will help us with our communication if there's anyone on this
          island.
        </span>

        <ImageViewer
          linkImage={Step3B}
          viewerImage={Step3B}
          footerText={"(Click to enlarge)"}
          style={{ maxWidth: "75%", minWidth: "40%" }}
          defaultScale={0.9}
        ></ImageViewer>
      </Fragment>,
    ],
    Content: [Step3A, Step3B],
  },
  {
    Id: "4",
    Type: EQuestionType.Input,
    Question:
      "In what order do the 6 buttons need to be pressed, from first to last button? Enter the button names and use a space between each button name.",
    Options: [""],
    Body: [
      <Fragment key={4}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>BUTTONS</span>
        <span className={"mt-2"}>
          The transponder code has been set, let's hope someone on the island
          will reach out.
        </span>
        <ImageViewer
          linkImage={`${process.env.PUBLIC_URL}/assests/GameFiles/MayDay/emergencymanualv7.pdf`}
          viewerImage={Step4A}
          footerText={"(Click to open)"}
          defaultScale={0.4}
          style={{ maxWidth: "35%", minWidth: "20%", padding: "20px" }}
        ></ImageViewer>
        <span className={"mt-3"}>
          Now let's look at the next step.
          <br />
          It looks like captain Cruise, who was flying this plane yesterday,
          spilled some coffee over some of the pages.
          <br /> Hopefully you're able to figure out in which order the buttons
          need to be pressed.
        </span>
        <Step2Map
          linkImage={Step4B}
          viewerImage={Step4B}
          footerText={"(Click buttons to find the correct sequence)"}
          style={{ maxWidth: "75%", minWidth: "40%" }}
          defaultScale={0.9}
        ></Step2Map>
      </Fragment>,
    ],
    Content: [Step4A, Step4B],
  },
  {
    Id: "5",
    Type: EQuestionType.Input,
    Question: "At what speed should Roger be flying to safely land?",
    Options: [""],
    InputPattern: "^[0-9]*$",
    InputMaxLimit: 2,
    Body: [
      <Fragment key={5}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>
          SETTING VELOCITY
        </span>
        <span className={"mt-2"}>
          Copy that, we've got the runway in sight.<br></br>
          But this plane is still out of control, we'll need to make sure we're
          flying at the right speed.
        </span>
        <ImageViewer
          linkImage={Step5A}
          viewerImage={Step5A}
          footerText={"(Click to enlarge)"}
          defaultScale={1.1}
          style={{ maxWidth: "55%", minWidth: "40%" }}
        ></ImageViewer>

        <span className={"mt-3"}>
          Have a look at the manual to determine the correct speed.
        </span>
        <ImageViewer
          linkImage={`${process.env.PUBLIC_URL}/assests/GameFiles/MayDay/emergencymanualv7.pdf`}
          viewerImage={Step3A}
          footerText={"(Click to open)"}
          defaultScale={0.4}
          style={{ maxWidth: "35%", minWidth: "20%", padding: "20px" }}
        ></ImageViewer>
      </Fragment>,
    ],
    Content: [Step5A],
  },
  {
    Id: "6",
    Type: EQuestionType.Input,
    Question:
      "To which coordinates on the map does Roger need to go to get to the lab?",
    Options: [""],
    Body: [
      <Fragment key={6}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>
          ROUGH LANDING
        </span>
        <span className={"mt-2"}>
          Ouch, that was a rougher than normal landing, but I'm happy I'm still
          alive!
          <br />
          Didn't take into consideration the heavy storm, so the plane is mostly
          scrap now.
          <br />
          Guess we'll need to find a different way off the island.
        </span>
        <span className={"mt-2"}>
          Wait, the radio still seems to be working and I hear something. Maybe
          setting the transponder helped.
          <br />
          The signal isn't great, but have a listen. It sounds like Louis is
          trying to contact us.
        </span>
        <Recording
          header={"AUDIO FILE"}
          path={"assests/GameFiles/MayDay/Step6.mp3"}
          hasSlider={true}
        ></Recording>
        <span
          className={"mt-3"}
          style={{ color: "#7ED321", fontSize: "0.8em" }}
        >
          FIND THE LAB
        </span>
        <span className={"mt-1"}>
          That sounds serious. Time to do what I came here for; find that cabin
          and rescue Louis.
          <br />
          <br />
          I'm not exactly sure where we are, but luckily there is a sign with a
          map.
          <br />
          Perhaps we can use his instructions and the information on the sign to
          figure out where to go.<br></br>
          Here's a photo of the map.
        </span>
        <ImageViewer
          linkImage={Step6A}
          viewerImage={Step6A}
          footerText={"(Click to enlarge)"}
          defaultScale={1}
          style={{ maxWidth: "75%", minWidth: "60%" }}
        ></ImageViewer>
        <span className={"mt-3"}>
          And here's a photo of the back of the sign with some beautiful photos.
        </span>
        <ImageViewer
          linkImage={Step6B}
          viewerImage={Step6B}
          footerText={"(Click to enlarge)"}
          defaultScale={1}
          style={{ maxWidth: "65%", minWidth: "60%" }}
        ></ImageViewer>
        <span className={"mt-3"}>
          There's an old car that hopefully gets us there.<br></br>
          I'll try and start it, but in the mean time, can you figure out the
          location?
        </span>
      </Fragment>,
    ],
    Content: [Step6A, Step6B],
  },
  {
    Id: "7",
    Type: EQuestionType.Input,
    Question: "What is the 4 digit code to get into the lab?",
    Options: [""],
    InputPattern: "^[0-9]*$",
    InputMaxLimit: 4,
    Body: [
      <Fragment key={7}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>GETTING IN</span>
        <span className={"mt-2"}>
          Made it to the lab, but it is a bit spooky here. Strange place to have
          a lab...
          <br />
          And it's a big mess, the storm has blown his garbage all over the
          place.
        </span>
        <span className={"mt-2"}>
          I've tried kicking in the door, but it is reinforced, so I won't be
          able to open it without the code.
          <br />
          And there's no windows to go through either, the lab is more like a
          bunker.
        </span>

        <Step7Map
          linkImage={Step7A}
          viewerImage={Step7A}
          footerText={"(click on each object until you find the correct one)"}
          style={{ maxWidth: "85%", minWidth: "40%" }}
          defaultScale={1}
        ></Step7Map>
        <span className={"mt-4"}>
          Can you help me go through the garbage and see if there's anything
          useful in there?
        </span>
      </Fragment>,
    ],
    Content: [Step7A, Step7B],
  },
  {
    Id: "8",
    Type: EQuestionType.Input,
    Question: "What is the 8 letter password to open the research file?",
    Options: [""],
    InputMaxLimit: 8,
    Body: [
      <Fragment key={8}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>
          ACCESS THE FILE
        </span>
        <span className={"mt-2"}>
          There's no sign of anyone in the lab, but the weather outside is
          really getting frightening.
          <br />
          We need to find his work, then find him and get off this island.
        </span>
        <ImageViewer
          linkImage={Step8A}
          viewerImage={Step8A}
          footerText={"(Click to enlarge)"}
          defaultScale={1}
          style={{ maxWidth: "75%", minWidth: "70%" }}
        ></ImageViewer>

        <span className={"mt-3"}>
          It seems he's been doing lots of research related to the soil and
          plants on this island.
          <br />
          I found a note that mentions an important file on his computer
          containing more information.
          <br />
          But the file is locked.
          <br />
          <br />
          Can you have a look at the post-it notes? Perhaps they could help us
          find the password to access the file.
        </span>
      </Fragment>,
    ],
    Content: [Step8A],
  },
  {
    Id: "9",
    Type: EQuestionType.Input,
    Question: "Which sample should Roger take with him?",
    Options: [""],
    Body: [
      <Fragment key={9}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>
          THE RIGHT SAMPLE
        </span>
        <span className={"mt-2"}>
          Ok, I've managed to get into his research file, but he seems to be
          very unorganised.
        </span>
        <span className={"mt-2"}>
          He spent years of experimenting with the unique local flora on the
          island.
          <br />
          The research focuses on modifications to the organelles of the plant
          cells that would allow plants to grow faster and make them more
          resistant to diseases.
        </span>
        <ImageViewer
          defaultScale={1}
          linkImage={Step9A}
          viewerImage={Step9A}
          footerText={"(Click to enlarge)"}
          style={{ maxWidth: "55%", minWidth: "40%" }}
        ></ImageViewer>
        <span className={"mt-4"}>
          The file notes a number of different samples he was trialling with,
          with varying results.
          <br />
          I'll put them under the microscope, have a look!
        </span>
        <SlideShow
          viewerImage={[
            { url: Step9B_A },
            { url: Step9B_B },
            { url: Step9B_C },
            { url: Step9B_D },
            { url: Step9B_E },
          ]}
          footerText={
            "(click arrows to view different samples and click image to zoom)"
          }
          defaultScale={1}
          style={{ maxWidth: "75%", minWidth: "40%" }}
        ></SlideShow>
        <span className={"mt-4"}>
          But there's just one problem, the samples are very fragile and require
          special conditions to keep them preserved. So I can only take one of
          them with me.
        </span>
        <span className={"mt-2"}>
          Can you have a look at the samples and his notes on the whiteboard and
          tell me which one to take?
        </span>
      </Fragment>,
    ],
    Content: [Step9A, Step9B_A, Step9B_B, Step9B_C, Step9B_D, Step9B_E],
  },
  {
    Id: "10",
    Type: EQuestionType.Input,
    Question: "What's the name of the plant to collect for Louis?",
    Options: [""],
    Body: [
      <Fragment key={10}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>
          MEETING LOUIS
        </span>
        <span className={"mt-2"}>
          Ok, with the sample collected, I'm on my way to meet Louis at the
          shelter.
        </span>
        <ImageViewer
          linkImage={Step10A}
          viewerImage={Step10A}
          footerText={"(Click to enlarge)"}
          style={{ maxWidth: "75%", minWidth: "70%" }}
          defaultScale={1}
        ></ImageViewer>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>
          FIND THE PLANT
        </span>
        <span className={"mt-1  mr-5"}>
          Found him!
          <br />
          <br />
          But he went out into the jungle again to find this last specimen
          before he finally wants to leave. And there's some scary looking
          plants out here.
          <br />
          He said that the plant he's looking for usually grows in Southeast
          Asia, so finding it here would be incredible. According to him this
          plant sometimes smells pretty badly, doesn't have any observable roots
          and usually grows fairly close to water.
        </span>
        <span className={"mt-2"}>
          I've brought the "Garden Your Way Out" magazine from his lab to have
          something to read on the way back. Who knows, it may come in handy.
        </span>
        <ImageViewer
          linkImage={Step10B}
          viewerImage={Step10B}
          footerText={"(Click to enlarge)"}
          defaultScale={1}
          style={{ maxWidth: "75%", minWidth: "75%" }}
        ></ImageViewer>
        <span className={"mt-3 mr-5"}>
          Can you have a look to see if any of the plants match his description?
          <br />
          If so, I'll see if I can find a specimen around here.
        </span>
      </Fragment>,
    ],
    Content: [Step10A, Step10B],
  },
  // {
  //   Id: "11",
  //   Type: EQuestionType.Input,
  //   Question: "???",
  //   Options: [""],
  //   Body: [
  //     <Fragment>
  //       <span style={{ color: "#7ED321", fontSize: "0.8em" }}>RUN!!!</span>
  //       <span className={"mt-2"}>
  //         That hurricane is really approaching.<br></br>
  //         Time to start running and find a way off this island.
  //       </span>
  //       <ImageViewer
  //         linkImage={Step11A}
  //         viewerImage={Step11A}
  //         footerText={"(Click to open)"}
  //         style={{ maxWidth: "45%", minWidth: "40%" }}
  //       ></ImageViewer>
  //       <span className={"mt-2"}>
  //         According to Asterix there's a boat docked somewhere.
  //       </span>
  //       <ImageViewer
  //         linkImage={Step11B}
  //         viewerImage={Step11B}
  //         footerText={"(Click to enlarge)"}
  //         defaultScale={0.38}
  //         style={{ maxWidth: "35%", minWidth: "30%" }}
  //       ></ImageViewer>
  //     </Fragment>,
  //   ],
  //   Content: [Step11A, Step11B],
  // },
  {
    Id: "11",
    Type: EQuestionType.InputButton,
    Question:
      "What are the positions to move to get to the same position as the oil tanker? Press the buttons in the sequence you think the boat should go.",
    Options: [""],
    InputMaxLimit: 7,
    Body: [
      <Fragment key={12}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>SET SAIL</span>
        <span className={"mt-2"}>
          Our work is done here, we've got everything we need. <br></br>
          I've driven to the harbour and the only ship still around is a small
          fishing vessel.
        </span>
        <span className={"mt-2"}>
          In the distance I can see a large oil tanker. Reaching it in time is
          our only hope of survival.
        </span>
        <ImageViewer
          linkImage={Step12A}
          viewerImage={Step12A}
          footerText={"(Click to enlarge)"}
          style={{ maxWidth: "45%", minWidth: "40%" }}
          defaultScale={1}
        ></ImageViewer>
        <span className={"mt-4"}>
          Our tiny vessel has a weather radar that shows our current position in
          the harbour, the oil tanker in the ocean and the predicted storm
          trajectory. Before we depart, I need you to plan the route.
        </span>
        <span className={"mt-2"}>
          We can sail through the light rain (white), but our boat isn't built
          to withstand more than that.<br></br>
          Make sure you avoid the hurricane (blue, yellow, orange, red and
          purple) at all cost.
        </span>
        {/* <ImageViewer
          linkImage={Step12B}
          viewerImage={Step12B}
          footerText={"(Click to enlarge)"}
          style={{ maxWidth: "65%", minWidth: "60%" }}
          defaultScale={1}
        ></ImageViewer> */}
        <SlideShow
          viewerImage={[
            { url: Step12B_A },
            { url: Step12B_B },
            { url: Step12B_C },
            { url: Step12B_D },
            { url: Step12B_E },
            { url: Step12B_F },
            { url: Step12B_G },
            { url: Step12B_H },
          ]}
          footerText={
            "(click arrows to view different images and click image to zoom)"
          }
          defaultScale={1}
          style={{ maxWidth: "75%", minWidth: "40%" }}
        ></SlideShow>
        <span className={"mt-4"}>
          Plan the 7 step route to safely get to the oil tanker, by pressing the
          NESW buttons.<br></br>
          We need to get to the same position as the large ship.
          <br />
          Once you think you've planned the correct route that avoids the storm,
          submit the answer.
        </span>
      </Fragment>,
    ],
    Content: [
      Step12A,
      Step12B_A,
      Step12B_B,
      Step12B_C,
      Step12B_D,
      Step12B_E,
      Step12B_F,
      Step12B_G,
      Step12B_H,
    ],
  },
];
