import React, { useContext } from "react";
import classes from "./teamslot.module.scss";
import { getTranslatedWord } from "../../metadata/ButtonText";
import { GamestateContext } from "../../context/GameState/GameStateContext";
import { EGameCompany } from "../../container/Enums/Enums";
import { isCompany } from "../../container/GameMenu/Menu";

const TeamSlot = () => {
  const { gameLanguage } = useContext(GamestateContext);
  return (
    <div className={classes["header-slot"]}>
      <span className={classes["rank-slot"]}>
        {getTranslatedWord("Rank", gameLanguage)}
      </span>
      {isCompany(EGameCompany.Experios) ? (
        <span className={classes["team-name-slot"]}>
          {getTranslatedWord("Company", gameLanguage)}
        </span>
      ) : null}
      <span className={classes["team-name-slot"]}>
        {getTranslatedWord("Team Name", gameLanguage)}
      </span>
      <span className={classes["hint-slot"]}>Hints</span>
      <span className={classes["hint-slot"]}>
        {getTranslatedWord("Incorrect", gameLanguage)}
      </span>
      <span className={classes["hint-slot"]}>
        {getTranslatedWord("Result", gameLanguage)}
      </span>
    </div>
  );
};

export default TeamSlot;
