import classes from "./videoPlayer.module.scss";
import poster from "../../../assets/BlackeyeTreasure/Step2.jpg";
import React, { useRef, useEffect } from "react";

const VideoPlayer = ({ videoUrl, ...rest }: any) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (videoUrl) {
      videoRef.current?.play();
    }
  }, [videoUrl]);

  return (
    <div className={classes["content-container"]} style={rest.style}>
      <video
        ref={videoRef}
        // controls
        poster={poster}
        style={{ objectFit: "cover",height:"auto", maxWidth:"100%", maxHeight:"416px",width:"100%", }} 
      >
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer;
