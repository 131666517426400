import React, { Fragment, useRef, useState } from "react";
import ImageMapper, {
  IArea,
  IImagePanel,
} from "../game/imageMapper/imageMapper";
import RedLight from "../../assets/MayDay/redledv1.jpg";
import GreenLight from "../../assets/MayDay/greenledv1.jpg";
const buttonSequence = [3, 1, 0, 4, 5, 2];

const defaultButtonState = [
  { coords: [800, 1500, 920, 1515], image: RedLight },
  { coords: [800, 1530, 920, 1610], link: true, pointerCursor: true },
  { coords: [960, 1500, 1080, 1515], image: RedLight },
  { coords: [960, 1530, 1080, 1610], link: true, pointerCursor: true },
  { coords: [1120, 1500, 1240, 1515], image: RedLight },
  { coords: [1120, 1530, 1240, 1610], link: true, pointerCursor: true },
  {
    coords: [1360, 1500, 1480, 1515],
    image: RedLight,
  },
  { coords: [1360, 1530, 1480, 1610], link: true, pointerCursor: true },
  {
    coords: [1520, 1500, 1640, 1515],

    image: RedLight,
  },
  { coords: [1520, 1530, 1640, 1610], link: true, pointerCursor: true },
  {
    coords: [1685, 1500, 1805, 1515],
    image: RedLight,
  },
  { coords: [1685, 1530, 1805, 1610], link: true, pointerCursor: true },
  { coords: [530, 1470, 720, 1650], link: true, pointerCursor: true },
];
const Step2Map: React.FC<IImagePanel> = ({
  linkImage,
  viewerImage,
  footerText,
  defaultScale,
  style,
}) => {
  const [areas, updateArea] = useState<IArea[]>([...defaultButtonState]);
  const currentPressButton = useRef<number[]>([]);
  const isValid = (index: number) => {
    const transformIndex = (index - 1) / 2;
    currentPressButton.current.push(transformIndex);
    const length = currentPressButton.current.length;
    const buttons = buttonSequence.slice(0, length);
    return (
      JSON.stringify(buttons) === JSON.stringify(currentPressButton.current)
    );
  };
  const onAreaClicked = (index: number) => {
    if (isValid(index)) {
      const copyArea: IArea[] = [...areas];
      let data: IArea = copyArea[index - 1];
      data = {
        ...data,
        image: data.image === GreenLight ? RedLight : GreenLight,
      };
      copyArea.splice(index - 1, 1, data);
      updateArea([...copyArea]);
    } else {
      resetToDefault();
    }
  };

  const resetToDefault = () => {
    currentPressButton.current = [];
    updateArea([...defaultButtonState]);
  };
  return (
    <Fragment>
      <ImageMapper
        linkImage={linkImage}
        viewerImage={viewerImage}
        footerText={footerText}
        style={style}
        defaultScale={defaultScale}
        dimensions={[2348, 1804]}
        areas={[...areas]}
        onAreaClick={onAreaClicked}
      ></ImageMapper>
    </Fragment>
  );
};

export default Step2Map;
