import React, { Fragment } from "react";
import LinkMapper from "../game/imageMapper/linkMapper";
import { ILinkMap } from "../game/imageMapper/linkMapper";
import { IArea } from "../game/imageMapper/imageMapper";

const defaultButtonState: IArea[] = [
  {
    coords: [895, 320, 1350, 400],
    link: true,
    pointerCursor: true,
  },
  { coords: [895, 420, 1350, 500], link: true, pointerCursor: true },
  { coords: [895, 520, 1350, 600], link: true, pointerCursor: true },
  { coords: [895, 620, 1350, 700], link: true, pointerCursor: true },
  { coords: [895, 720, 1350, 800], link: true, pointerCursor: true },
  { coords: [895, 820, 1350, 900], link: true, pointerCursor: true },
  { coords: [895, 920, 1350, 1000], link: true, pointerCursor: true },
  { coords: [895, 1020, 1350, 1100], link: true, pointerCursor: true },
  { coords: [895, 1120, 1350, 1200], link: true, pointerCursor: true },
  { coords: [895, 1220, 1350, 1300], link: true, pointerCursor: true },
  { coords: [895, 1320, 1350, 1400], link: true, pointerCursor: true },
  { coords: [895, 1420, 1350, 1500], link: true, pointerCursor: true },
  { coords: [895, 1520, 1350, 1600], link: true, pointerCursor: true },
  { coords: [895, 1620, 1350, 1700], link: true, pointerCursor: true },
];
const LINKS = [
  "maps.app.goo.gl/iEoVQLKuekCkQ1Sh7",
  "www.citydiscovery.com.au",
  "www.google.com",
  "www.theengineer.co.uk",
  "en.wikipedia.org",
  "www.mindgamesmelbourne.com",
  "www.facebook.com",
  "www.google.com",
  "www.youtube.com",
  "www.bjornwilly.com.au",
  "www.google.com",
  "www.google.com",
  "www.telstra.com.au",
  "www.google.com",
];

const LinkMap: React.FC<ILinkMap> = ({ image, linkText }) => {
  const onAreaClicked = (index: number) => {
    window.open("http://" + LINKS[index], "_blank");
    // window.open("http://" + LINKS_TT[index], "_blank");
  };

  return (
    <Fragment>
      <LinkMapper
        image={image}
        linkText={linkText}
        dimensions={[1530, 1718]}
        areas={[...defaultButtonState]}
        onAreaClick={onAreaClicked}
      ></LinkMapper>
    </Fragment>
  );
};

export default LinkMap;
