import React, { useContext } from "react";
import { RouteProps, Route, Redirect } from "react-router";
import { AuthContext } from "../../context/Auth/AuthContext";
import { GamestateContext } from "../../context/GameState/GameStateContext";
import { ROUTE_LOGIN, ROUTE_LOGIN_NL, ROUTE_MENU } from "../../constants";
import { GameLanguages, EGameScreen } from "../Enums/Enums";

interface PrivateRouteProps extends RouteProps {
  currentScreen?: EGameScreen;
  component: any;
}

const PrivateRoute: React.FunctionComponent<PrivateRouteProps> = ({
  component: Component,
  currentScreen = EGameScreen.None,
  ...rest
}) => {
  const authValues = useContext(AuthContext);
  const { screen, gameLanguage } = useContext(GamestateContext);

  const RedirectToLogin = (): string => {
    switch (gameLanguage) {
      case GameLanguages.DUTCH:
        return ROUTE_LOGIN_NL;
      default:
        return ROUTE_LOGIN;
    }
  };
  switch (currentScreen) {
    case EGameScreen.Question:
      if (screen === EGameScreen.Question) {
        return (
          <Route
            {...rest}
            render={(props) =>
              authValues.checkAuthentication() ? (
                <Component {...props} />
              ) : (
                <Redirect to={RedirectToLogin()} />
              )
            }
          />
        );
      } else {
        return (
          <Route
            {...rest}
            render={(props) =>
              authValues.checkAuthentication() ? (
                <Redirect to={ROUTE_MENU} />
              ) : (
                <Redirect to={RedirectToLogin()} />
              )
            }
          />
        );
      }
    default:
      return (
        <Route
          {...rest}
          render={(props) =>
            authValues.checkAuthentication() ? (
              <Component {...props} />
            ) : (
              <Redirect to={RedirectToLogin()} />
            )
          }
        />
      );
  }
};

export default PrivateRoute;
