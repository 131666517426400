import React from "react";
import classes from "./dropdown.module.scss";

interface IDropDown extends React.SelectHTMLAttributes<HTMLSelectElement> {
  options: string[];
}

const DropDown: React.FC<IDropDown> = ({ options, ...rest }) => {
  const availableOptions = options.map((x, index) => {
    return (
      <option key={index} value={x}>
        {x}
      </option>
    );
  });
  return (
    <select className={`${classes["drop-down"]}`} {...rest}>
      {availableOptions}
    </select>
  );
};

export default DropDown;
